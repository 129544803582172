import FreeResume from './free/FreeResume';
import DeedyResume from './deedy/DeedyResume';
import DefaultPremiumResume from './default_premium/DefaultPremiumResume';
import RussellResume from './russ/RussellResume';
import { Button } from '@mui/material';
import { useIsMobile } from '../shared/UseIsMobile';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useContext, useState } from 'react';
import { AuthContext } from '../shared/Login/AuthContext';
import PickResume from './Picker';
import jake from '../assets/jake.png';
import deedy from '../assets/deedy.png';
import fang from '../assets/fang.png';
import russ from '../assets/russ.png';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../config';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    semiLargeText: {
      fontSize: '1.55em',
      textAlign: 'center',
      fontFamily: "'Nunito Sans', sans-serif",
    },
    subtitleText: {
      fontSize: '1.5em',
      textAlign: 'center',
      fontFamily: "'Nunito Sans', sans-serif",
    },
  });
});

const Resume = () => {
  const classes = useStyles();
  const { isLoggedIn, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);
  const isMobile = useIsMobile({
    width: 1020,
  });
  const [open, setPopup] = useState(false);
  const resumes = [
    {
      resume: <FreeResume />,
      description:
        'Excellent general purpose template, for students and industry.',
      season: '2020 recruiting season template',
      companies: ['Microsoft intern - phone (2019)'],
      photo: jake,
    },
    {
      resume: <RussellResume />,
      description: `This is the template I am using right now.
       It allows you to squeeze a lot of information onto a single page while still being precise.
      Perfect for if you have a lot of work experience.`,
      season: '2023 recruiting season template',
      companies: [
        'Google NG (before freeze) - phone (2022)',
        'Databricks NG - phone (2022)',
        'Scale AI NG - OA (2022)',
        'Palantir NG - phone (2022)',
        'Akuna Capital (Python) intern - phone (2022)',
      ],
      photo: russ,
    },
    {
      resume: <DeedyResume />,
      description:
        'Best template for undergraduate students, this template emphasis coursework and extra-curriculars. Made by Deedy (ex-Google, founder of Glean - a $1 billion dollar SaaS unicorn.) ',
      season: '2022 recruiting season template',
      companies: [
        'Nuro intern - phone (2021)',
        'Citadel intern - phone (2021)',
        'IMC trading intern - phone (2021)',
      ],
      photo: deedy,
    },
    {
      resume: <DefaultPremiumResume />,
      description:
        'This template emphasis your role, instead of company branding. If you want to focus on impact, this is a great template for that. ',
      season: '2021 recruiting season template',
      companies: [
        'SAP intern - phone (2020)',
        'Amazon intern - OA (2020)',
        'Capital One intern - phone (2020)',
      ],
      photo: fang,
    },
  ];
  const [resume, setResume] = useState(0);
  return (
    <div>
      <PickResume
        open={open}
        handleClose={() => setPopup(false)}
        currResume={resume}
        setResume={setResume}
        resumes={resumes}
      />
      <div
        style={{
          width: '270px',
          textAlign: 'center',
          margin: 'auto',
          backgroundColor: '#1976d2',
          color: '#fff',
          borderRadius: '20px',
        }}
      >
        {!isLoggedIn && (
          <h2 className={classes.subtitleText}>Use Google Chrome.</h2>
        )}
      </div>
      {isLoggedIn && (
        <h2 className={classes.semiLargeText}>{resumes[resume]['season']}</h2>
      )}
      {!isLoggedIn && (
        <div>
          <h2 className={classes.subtitleText}>
            Interviews I got using this template:{' '}
          </h2>
          <ul style={{ width: isMobile ? '200px' : '350px', margin: 'auto' }}>
            <li
              style={{
                textAlign: 'center',
                fontFamily: '"Nunito Sans", sans-serif',
                fontSize: isMobile ? '1em' : '1.5em',
              }}
            >
              Microsoft intern - phone (2019)
            </li>
          </ul>
        </div>
      )}
      <div
        style={{
          margin: '15px',
          float: isMobile ? 'none' : 'right',
          width: isMobile ? '90%' : '47%',
          borderStyle: 'solid',
          borderWidth: 'thick',
        }}
      >
        <Button
          variant="contained"
          color={'primary'}
          style={{ margin: '10px' }}
          onClick={() => setPopup(true)}
        >
          {<b>Switch template</b>}
        </Button>
        {!isLoggedIn && (
          <h3
            style={{ margin: '10px', fontFamily: '"Nunito Sans", sans-serif' }}
          >
            Download / Preview: None of this{' '}
            <i>"pay at the end to download after you edited for hours"</i>{' '}
            bullshit.{' '}
          </h3>
        )}
        {canAccess && (
          <h3
            style={{ margin: '10px', fontFamily: '"Nunito Sans", sans-serif' }}
          >
            Feel free to reach out to jackhe3345@gmail.com (my personal email) if you have any questions, feedback, 
            feature requests, issues... etc. 
          </h3>
        )}
      </div>
      {resumes[resume]['resume']}
    </div>
  );
};

export default Resume;
