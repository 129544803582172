import { Card, CardContent } from '@material-ui/core';

const Privacy = () => {
  return (
    <Card style={{ margin: '30px', fontFamily: '"Nunito Sans", sans-serif' }}>
      <CardContent>
        <h1>Privacy policy</h1>
        <p>
          RealTechPrep.com ("RealTechPrep" "we" or "us") is committed to
          protecting your privacy. This Privacy Policy explains the methods and
          reasons we collect, use, disclose, transfer, and store your
          information. If you have any questions about the contents of this
          policy, don't hesitate to contact us. If you do not consent to the
          collection and use of information from or about you in accordance with
          this Privacy Policy, then you are not permitted to use RealTechPrep or any
          services provided on https://realtechprep.com.
        </p>
        <h2>Information we store</h2>
        <p>
          Information you submit: When you sign up for RealTechPrep using Google, we ask for
          your email address only for identification purposes, if you sign up with 
          Github, we ask for your Github username only. We do not store any passwords 
          or other identifying information. We offload authentication to be handled by third party 
          services such as Google / Github OAuth2. In addition, we store your progress while 
          using the resume builder.
        </p>
        <h2>Use of that information</h2>
        <p>RealTechPrep may use your email address to inform you when an interested company wants to interview you.
           This information comes from your resume submission.
        </p>
        <h2>Children and COPPA</h2>
        <p>
          RealTechPrep is committed to complying with the Children's Online
          Privacy Protection Act (COPPA). RealTechPrep does not knowingly
          collect personally identifiable information from or market to children
          under the age of thirteen. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
        </p>
        <h2>Contact us</h2>
        <p>
          Any issues you may have using our services, or additioinal information
          about this privacy policy, contact us at jack@jackhetech.io
        </p>
      </CardContent>
    </Card>
  );
};

export default Privacy;
