import React, { useRef, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import timer from './timer.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

export default function MobileCard(props: any) {
  const cardRef = useRef<HTMLDivElement>(null);
  const projectTitle = props.projectTitle;
  const projectDescription = props.projectDescription;
  const projectVideoId = props.projectVideoId;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getCenterPositionXY = () => {
    if (cardRef.current !== null) {
      const rect = cardRef.current.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const x = rect.x;
      const y = rect.y;
      // x and y are the coordinates of the top left corner of the div.
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      return [centerX, centerY];
    }
  };

  useEffect(() => {
    if (props.shouldHighlight) {
      const center = getCenterPositionXY();
      if (center) {
        window.scrollBy(0, center[1] - 200);
      }
    }
  }, []);

  return (
    <div className={props.dim ? 'dim' : 'not-dim'}>
      <Card
        className={classes.root}
        style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '50px' }}
        ref={cardRef}
        onClick={() => {
          if (props.shouldHighlight) {
            props.setDidHover(false);
            props.setDim(false);
          }

          if (!props.img) {
            props.buttonCallback();
          }
        }}
      >
        {props.img && (
          <div className="project-video">
            <a href="#" onClick={props.buttonCallback}>
              <img src={props.img} className="project-video"></img>
            </a>
          </div>
        )}
        
        {props.duration && (
          <div style={{ margin: '20px', display: 'flex' }}>
            <img src={timer} style={{ marginRight: '10px' }}></img>
            <Typography variant="h5"> {props.duration}</Typography>
          </div>
        )}

        <Typography
          variant="h5"
          style={{
            fontFamily: '"Nunito Sans", sans-serif',
            textAlign: 'center',
          }}
        >
          <b>{projectTitle}</b>
        </Typography>

        <Typography
          variant="h6"
          style={{
            fontFamily: '"Nunito Sans", sans-serif',
            textAlign: 'center',
            margin: '10px',
          }}
        >
          {props.shortDescription
            ? props.shortDescription
            : 'Learn fullstack development through hands on experience.'}
        </Typography>

        <CardActions
          disableSpacing
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              paragraph
              style={{ fontFamily: '"Nunito Sans", sans-serif' }}
            >
              {projectDescription}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
