import { useContext, useEffect, useState } from 'react';
import { XETEX_ENGINE } from '../XeTeXEngine';
import { DVI_ENGINE } from '../DvipdfmxEngine';
import { render } from '../RenderTemplate';
import { AuthContext } from '../../shared/Login/AuthContext';
import { fetchTemplate } from '../Util';
import Previewer from '../editor/Previewer';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../../config';

const DeedyResume = () => {
  const [xetexEngine, setXetexEngine] = useState<any>(null);
  const [dviEngine, setDviEngine] = useState<any>(null);
  const [pdfObject, setPdfObject] = useState('');
  const [deedyTemplate, setDeedyTemplate] = useState(null);
  const { token, isLoggedIn, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);

  useEffect(() => {
    let font_paths = [
      'assets/deedy/fonts/lato/Lato-Bla.ttf',
      'assets/deedy/fonts/lato/Lato-BlaIta.ttf',
      'assets/deedy/fonts/lato/Lato-Bol.ttf',
      'assets/deedy/fonts/lato/Lato-BolIta.ttf',
      'assets/deedy/fonts/lato/Lato-Hai.ttf',
      'assets/deedy/fonts/lato/Lato-HaiIta.ttf',
      'assets/deedy/fonts/lato/Lato-Lig.ttf',
      'assets/deedy/fonts/lato/Lato-LigIta.ttf',
      'assets/deedy/fonts/lato/Lato-Reg.ttf',
      'assets/deedy/fonts/lato/Lato-RegIta.ttf',
      'assets/deedy/fonts/raleway/Raleway-Bold.otf',
      'assets/deedy/fonts/raleway/Raleway-ExtraBold.otf',
      'assets/deedy/fonts/raleway/Raleway-ExtraLight.otf',
      'assets/deedy/fonts/raleway/Raleway-Heavy.otf',
      'assets/deedy/fonts/raleway/Raleway-Light.otf',
      'assets/deedy/fonts/raleway/Raleway-Medium.otf',
      'assets/deedy/fonts/raleway/Raleway-Regular.otf',
      'assets/deedy/fonts/raleway/Raleway-SemiBold.otf',
      'assets/deedy/fonts/raleway/Raleway-Thin.otf',
    ];

    const initializePdfLatexEngine = async () => {
      // TODO: pre-cache fonts, so we don't need to fetch from backend
      // TODO: refactor code to be more reusable
      // TODO: hard-code CLS in this file instead of fetching from backend once again
      try {
        console.log('Initializing XeTex PDF engine.');
        let globalEn = new XETEX_ENGINE(
          '/pdf-engine/deedy/deedy_resume_xelatex.js',
        );
        let dvipdfmxEn = new DVI_ENGINE(
          '/pdf-engine/deedy/deedy_resume_dvipdfm.js',
        );

        // Setup
        await globalEn.loadEngine();
        await dvipdfmxEn.loadEngine();

        let openFontResponse = await fetch(
          '/pdf-engine/deedy/assets/deedy/deedy-resume-openfont.cls',
        );
        let openFontCls = await openFontResponse.text();

        globalEn.makeMemFSFolder('fonts/');
        globalEn.makeMemFSFolder('fonts/lato/');
        globalEn.makeMemFSFolder('fonts/raleway/');
        dvipdfmxEn.makeMemFSFolder('fonts/');
        dvipdfmxEn.makeMemFSFolder('fonts/lato/');
        dvipdfmxEn.makeMemFSFolder('fonts/raleway/');
        for (let i = 0; i < font_paths.length; i++) {
          let font = await fetch('/pdf-engine/deedy/' + font_paths[i]);
          let buf = await font.arrayBuffer();
          let arrayBuf = new Uint8Array(buf);
          let pathArr = font_paths[i].split('/');
          pathArr.reverse();
          pathArr.pop();
          pathArr.pop();
          pathArr.reverse();
          let path = pathArr.join('/');
          console.log(path);
          globalEn.writeMemFSFile(path, arrayBuf);
          dvipdfmxEn.writeMemFSFile(path, arrayBuf);
        }
        globalEn.writeMemFSFile('deedy-resume-openfont.cls', openFontCls);
        setXetexEngine(globalEn);
        setDviEngine(dvipdfmxEn);
        console.log('XeTex and DVI engine initialized.');
      } catch (err) {
        alert('Error loading Xetex or DVI engine: ' + err);
      }
    };
    if (
      xetexEngine === null &&
      dviEngine === null &&
      pdfObject.length === 0 &&
      deedyTemplate !== null
    ) {
      initializePdfLatexEngine();
    }
    if (deedyTemplate === null && canAccess) {
      fetchTemplate(
        '/api/content/resume/premium/deedy',
        token,
        setDeedyTemplate,
      );
    }
  }, [deedyTemplate, canAccess]);

  const compile = async (resumeObj: any, callbackFunction: any) => {
    if (xetexEngine === null || dviEngine === null) {
      console.log('engines not ready yet!');
      return;
    }
    let compiledResult = '';
    try {
      // Compile
      compiledResult = render(resumeObj, deedyTemplate);
      console.log(compiledResult);
      xetexEngine.writeMemFSFile('main.tex', compiledResult);
      xetexEngine.setEngineMainFile('main.tex');
      let result = await xetexEngine.compileLaTeX();
      if (result.status === 0) {
        dviEngine.writeMemFSFile('main.xdv', result.pdf);
        dviEngine.setEngineMainFile('main.xdv');
        let final_result = await dviEngine.compilePDF();
        const pdfblob = new Blob([final_result.pdf], {
          type: 'application/pdf',
        });
        const objectURL = URL.createObjectURL(pdfblob);
        setPdfObject(objectURL);
      } else {
        throw new Error(JSON.stringify(result));
      }
    } catch (err) {
      alert('Error compiling PDF. Error: ' + err);
    }
    if (callbackFunction) callbackFunction(compiledResult);
  };

  return (
    <Previewer
      compile={compile}
      pdfObject={pdfObject}
      engineIsReady={xetexEngine !== null && dviEngine !== null}
      parentTemplate={'deedy'}
    />
  );
};

export default DeedyResume;
