import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './HoverCard.scss';
import { Button } from '@material-ui/core';
import MobileCard from './MobileCard';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import timer from './timer.svg';

// material UI div styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 500,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

/**
 * To maintain proper styling and even hovering,
 * the further to the left a card is,
 * the less calc(x, y) => (x_0, y_0) should produce.
 * the further to the right a card is, the more calc(x, y)
 * should produce.
 *
 */

/**
 *
 * @param {*} props
 * @param {String} props.projectTitle
 * @param {String} props.projectDescription
 * @param {String} props.projectVideoId
 * @param {boolean} props.isMobileView
 *
 */

let _mouseOver = false; 

function HoverCard(props) {
  const projectTitle = props.projectTitle;
  const projectDescription = props.projectDescription;
  const projectVideoId = props.projectVideoId;
  const projectGithubUrl = props.projectGithubUrl; // for free projects only
  const isMobileView = props.isMobileView;
  const [activeInterval, setActiveInterval] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [openPopup, toggle] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // highlight THIS card:
    // SHOULD we highlight?
    // let stop = highlight();
    _mouseOver = false;
    if (props.shouldHighlight && !isMobileView) {
      window.scrollBy(0, getCenterPositionXY()[1] - 250);
      let grow = 0;
      let interval = setInterval(() => {
        if (grow % 2 == 0) {
          set({ xys: [0, 0, 1.1] });
        } else {
          set({ xys: [0, 0, 1] });
        }
        grow++;
        console.log(_mouseOver);
        if (_mouseOver) clearInterval(interval);
      }, 500);
      setActiveInterval(interval);
      return () => {
        clearInterval(activeInterval);
      };
    }
  }, []);

  const classes = useStyles();
  const [blob, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  // changes [x, y, s] to 'string'
  const trans = (x, y, s) =>
    `perspective(300px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
  // sigmoid normalizes t to be between -k and k
  // alpha controls the smoothness of the transformation.
  // t_center is where the origin should be based.
  const sigmoid = (t, t_center, k, alpha) => {
    return (
      2 *
        k *
        (Math.E ** ((t - t_center) / alpha) /
          (Math.E ** ((t - t_center) / alpha) + 1)) -
      k
    );
  };

  const cardRef = useRef();

  // card button for handling email popups:
  const handleGetCode = () => {
    // check local storage to see if popup has already been shown.
    if (!localStorage.getItem('visited')) {
      toggle(true);
    } else {
      window.open(projectGithubUrl, '_blank');
    }
  };

  const rejection = () => {
    toggle(false);
  };

  // x and y are degrees
  // -3 <= x <= 3, -3 <= y <= 3
  /**
   * we need the center coordinate of the current project card on hover.
   */
  const calc = (x, y) => {
    const center = getCenterPositionXY();
    const rotateX = sigmoid(x, center[0], 2, 80);
    const rotateY = sigmoid(y, center[1], 2, 80);
    return [-rotateY, rotateX, 1.05];
  };

  const getCenterPositionXY = () => {
    const rect = cardRef.current.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    const x = rect.x;
    const y = rect.y;
    // x and y are the coordinates of the top left corner of the div.
    const centerX = x + width / 2;
    const centerY = y + height / 2;
    return [centerX, centerY];
  };

  let desktopView = (
    <animated.div
      ref={cardRef}
      className={classes.root}
      onMouseMove={({ clientX: x, clientY: y }) => {
        if (props.shouldHighlight) {
          _mouseOver = true;
          props.setDim(false);
        }
        clearInterval(activeInterval);
        set({ xys: calc(x, y) });
      }}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        transform: blob.xys.interpolate(trans),
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Card
        className={props.dim ? 'dim' : 'not-dim'}
        variant="elevation"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '30px',
          marginBottom: '30px',
          width: '500px',
        }}
      >
        {props.img && (
          <div className="project-video">
            {/* <a
              href="#"
              onClick={
                props.buttonCallback ? props.buttonCallback : handleExpandClick
              }
            > */}
              <img src={props.img} className="project-video"></img>
            {/* </a> */}
          </div>
        )}

        {props.duration && (
          <div style={{ margin: '20px', display: 'flex' }}>
            <img src={timer} style={{ marginRight: '10px' }}></img>
            <Typography variant="h5"> {props.duration}</Typography>
          </div>
        )}

        {props.img && <br></br>}
        
        <Typography
          variant="h5"
          style={{
            fontFamily: '"Nunito Sans", sans-serif',
            textAlign: 'center',
          }}
        >
          <b>{projectTitle}</b>
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: '"Nunito Sans", sans-serif',
            textAlign: 'center',
            margin: '10px',
          }}
        >
          {props.shortDescription
            ? props.shortDescription
            : 'Learn fullstack development through hands on experience.'}
        </Typography>

        <CardActions
          disableSpacing
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Button
            color="default"
            variant="outlined"
            onClick={
              props.buttonCallback ? props.buttonCallback : handleExpandClick
            }
          >
            {props.buttonText ? props.buttonText : 'Learn more'}
          </Button>

          {props.additionalLinks &&
            props.additionalLinks.map((linkTuple) => {
              return (
                <Link component="button" variant="body2" href={linkTuple[1]}>
                  {linkTuple[0]}
                </Link>
              );
            })}

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              paragraph
              style={{ fontFamily: '"Nunito Sans", sans-serif' }}
            >
              {projectDescription}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </animated.div>
  );

  return (
    <React.Fragment>
      {isMobileView ? (
        <MobileCard
          projectTitle={projectTitle}
          projectDescription={projectDescription}
          projectVideoId={projectVideoId}
          setDim={props.setDim}
          dim={props.dim}
          shouldHighlight={props.shouldHighlight}
          setDidHover={props.setDidHover}
          handleGetCode={handleGetCode}
          handleClose={rejection}
          img={props.img}
          buttonCallback={props.buttonCallback}
          shortDescription={props.shortDescription}
          duration={props.duration}
        />
      ) : (
        desktopView
      )}
    </React.Fragment>
  );
}

export default HoverCard;
