import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  name: '',
  accountType: '',
  token: '',
  email: '',
  githubUsername: '',
  proExpirationDate: new Date('0000-01-01'),
  image: '',
  login: (
    _token: string,
    _expiration: Date | null,
    _email: string,
    _githubUsername: string,
    _accountType: string,
    _name: string,
    _proExpirationDate: Date,
    _image: string
  ) => {},
  logout: () => {},
});
