import yt from '../../assets/youtube.png';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <ul>
        <li><h3>CONTACT: jack@jackhetech.io</h3></li>
      </ul>
      <ul className="icons">
        <li><a href="https://www.youtube.com/c/jackhetech" target={"_blank"}><img src={yt} height="48px"></img></a></li>
      </ul>
      <ul className="menu">
        <li>
          <a href="/terms">Terms</a>
        </li>
        <li>
          <a href="/privacy">Privacy</a>
        </li>
      </ul>
      <ul>
        <li><p>Copyright © 2023 RealTechPrep.com All rights reserved.</p></li>
      </ul>
    </footer>
  );
};

export default Footer;
