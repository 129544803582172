import { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  CardActions,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../config';
import { Card, CardContent } from '@material-ui/core';
import { useIsMobile } from '../shared/UseIsMobile';
import { AuthContext } from '../shared/Login/AuthContext';

/**
 * 0 -> jakes
 * 1 -> fang
 * 2 -> deedy
 * 3 -> russ
 * @param props
 * @returns
 */

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      margin: '10px',
      fontFamily: '"Nunito Sans", sans-serif',
      overflow: 'scroll',
      display: 'block',
      overflowX: 'scroll',
    },
    field: {
      margin: '5px',
    },
  });
});

const PickResume = (props: any) => {
  const { open, handleClose, currResume, setResume, resumes } = props;
  const { isLoggedIn, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);
  const isMobile = useIsMobile({
    width: 650,
  });
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Templates I used in the past to land interviews
      </DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          {resumes.map((resume: any, index: number) => {
            return (
              <Card variant="outlined" style={{ margin: '10px' }}>
                <CardContent>
                  <div
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      border: '0px solid',
                      width: '100%',
                    }}
                  >
                    <h2>{resume.season}</h2>
                    {/* <img
                      src={resume.photo}
                      width={isMobile ? '220px' : '350px'}
                      style={{ border: '1px solid' }}
                    ></img> */}
                    <p style={{ margin: '10px' }}>{resume.description}</p>
                    <p style={{ margin: '10px' }}>
                      <b>Interviews I got using this template:</b>
                    </p>
                    <ul style={{ width: '80%', margin: 'auto' }}>
                      {resume.companies.map((company: string) => {
                        return <li>{company}</li>;
                      })}
                    </ul>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      setResume(index);
                      handleClose();
                    }}
                    variant="outlined"
                    disabled={index > 0 && !canAccess}
                  >
                    <b>Use template</b>
                  </Button>
                  {index === currResume && (
                    <p>(You're currently using this template)</p>
                  )}
                  {index > 0 && !canAccess && (
                    <p>
                      <b>
                        <a href="/enroll">
                          Enroll to become a member or join the express waitlist
                          to unlock!
                        </a>
                      </b>
                    </p>
                  )}
                </CardActions>
              </Card>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickResume;
