import { useState, useCallback, useEffect } from 'react';
import { LOCAL_STORAGE_KEY_ONLINE } from '../../config';
let logoutTimer: NodeJS.Timeout | null | number = null;

export const useAuth = () => {
  const [token, setToken] = useState('');
  const [proExpirationDate, setProExpirationDate] = useState<Date>(new Date('0000-01-01'));
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);
  const [email, setEmail] = useState('');
  const [githubUsername, setGithubUsername] = useState('');
  const [accountType, setAccountType] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');

  const login = useCallback(
    (
      token: string,
      expiration: Date | null,
      email: string,
      githubUsername: string,
      accountType: string,
      name: string,
      proExpirationDate: Date,
      image: string
    ) => {
      console.log("LOGIN");
      const tokenExpirationData =
        expiration || new Date(new Date().getTime() + 3 * 1000 * 60 * 60); // token expires in 3 hours
      setToken(token);
      setName(name);
      setEmail(email);
      setGithubUsername(githubUsername);
      setAccountType(accountType);
      setTokenExpiration(tokenExpirationData);
      setProExpirationDate(new Date(proExpirationDate));
      setImage(image);
      localStorage.setItem(
        'memberData',
        JSON.stringify({
          token: token,
          expiration: tokenExpirationData.toISOString(),
          name: name,
          accountType: accountType,
          email: email,
          githubUsername: githubUsername,
          proExpirationDate: proExpirationDate,
          image: image,
        }),
      );
    },
    [],
  );

  const logout = useCallback(() => {
    console.log("LOGOUT");
    setToken('');
    setName('');
    setTokenExpiration(null);
    setEmail('');
    setGithubUsername('');
    setAccountType('');
    setImage('');
    setProExpirationDate(new Date('0000-01-01'));
    localStorage.removeItem('memberData');
    localStorage.removeItem(LOCAL_STORAGE_KEY_ONLINE);
  }, []);

  useEffect(() => {
    if (token && tokenExpiration) {
      const remainingTime = tokenExpiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      if (logoutTimer !== null) clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpiration]);

  useEffect(() => {
    // useEffect always run after render cycle
    const memberDataStore = localStorage.getItem('memberData');
    let storedData = null;
    if (memberDataStore) storedData = JSON.parse(memberDataStore);
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        new Date(storedData.expiration),
        storedData.email,
        storedData.githubUsername,
        storedData.accountType,
        storedData.name,
        storedData.proExpirationDate,
        storedData.image,
      );
    }
  }, [login]);

  return {
    token,
    login,
    logout,
    accountType,
    name,
    email,
    githubUsername,
    proExpirationDate,
    image,
  };
};
