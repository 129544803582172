import { Button } from '@mui/material';
import React, { useContext, useRef } from 'react';
import { AuthContext } from '../shared/Login/AuthContext';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      margin: '30px',
      fontFamily: '"Nunito Sans", sans-serif',
    },
    title: {
      textAlign: 'center',
      fontFamily: '"Nunito Sans", sans-serif',
    },
  });
});

const Profile = () => {
  const classes = useStyles();
  const {
    token,
    isLoggedIn,
    logout,
    name,
    email,
    githubUsername,
    accountType,
    proExpirationDate,
  } = useContext(AuthContext);
  const emailRef = useRef<HTMLInputElement>(null);
  const updateEmail = async () => {
    let newEmail = emailRef.current?.value;
    try {
      let response = await fetch('/api/util/update-email', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newEmail }),
      });
      let data = await response.json();
      if (!response.ok) {
        throw new Error(data['message']);
      }
      alert('Email updated successfully! Please log in again.');
      logout();
    } catch (e) {
      alert(e);
    }
  };
  const isPro = proExpirationDate && new Date(proExpirationDate) > new Date();
  const view = (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <h3>Hi, {name}!</h3>
          <p>
            <b>Account Type:</b> {accountType}
          </p>
          {isPro ? (
            <p>
              <b>You are a pro member!</b>
            </p>
          ) : (
            <p>
              <b>You are not a pro member ...yet</b>
            </p>
          )}
          {accountType === 'github' ? (
            <div>
              <p>
                <b>Github Username:</b> {githubUsername}
              </p>
              <p>
                <b>Email: </b>{' '}
                <input defaultValue={email} ref={emailRef}></input>
                <button color="warning" onClick={updateEmail}>
                  Update email
                </button>
              </p>
            </div>
          ) : (
            <p>
              <b>Email:</b> {email}
            </p>
          )}
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              logout();
              window.location.href = '/';
            }}
          >
            <b>Sign out</b>
          </Button>
        </CardContent>
      </Card>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isLoggedIn ? (
        view
      ) : (
        <h1
          style={{
            textAlign: 'center',
            fontFamily: '"Nunito Sans", sans-serif',
          }}
        >
          Log in to view your profile
        </h1>
      )}
    </React.Fragment>
  );
};

export default Profile;
