import { useEffect, useState, useDebugValue } from 'react';
import { isMobile } from 'react-device-detect';

export function useIsMobile({
  width = 768,
  customAgent = navigator.userAgent,
} = {}) {
  const [isMobileState, setIsMobile] = useState(
    window.innerWidth <= width || isMobile,
  );

  useEffect(() => {
    function handleResize() {
      if (global.window.innerWidth <= width || isMobile) {
        if (!isMobileState) {
          setIsMobile(true);
        }
      } else if (isMobileState) {
        setIsMobile(false);
      }
    }

    global.window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  });

  useDebugValue('useIsMobile');

  return isMobileState;
}
