import VideosView from './VideosView';
import {
  GET_THE_EXPERIENCE,
  GET_THE_INTERVIEW,
  GET_THE_OFFER,
} from './content-structure';
import { useHistory } from 'react-router-dom';
import HoverCard from '../shared/HoverCard/HoverCard';
import { useIsMobile } from '../shared/UseIsMobile';
import { makeStyles, Theme, createStyles, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import queryString from "query-string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flexGrow: 1,
      display: 'flex',
      marginRight: '30px',
      marginLeft: '30px',
    },
    column: {
      margin: '10px',
    },
    button: {
        margin: '30px'
    },
    text: {
        textAlign: 'center',
        margin: '30px'
    }
  }),
);

export const GetTheExperience = () => {
  const isMobile = useIsMobile({
    width: 768,
  });
  const screenTooNarrow = useIsMobile({
    width: 1618,
  });
  const classes = useStyles();
  const [allowDim, restrictDim] = useState(true);
  const history = useHistory();
  const { p } = queryString.parse(window.location.search);
  const firstRow = (
    <div className={screenTooNarrow ? classes.column : classes.row}>
        <HoverCard
        projectTitle="Digital Flipbook"
        projectDescription={`Recommended lesson to start with for people with experience levels L1 - L3. The goal is to 
        decouple yourself from a framework or technology, and learn the fundamentals so you can adapt when trends changes.`}
        shortDescription="Emphasis on the fundamentals of web development, web technologies, and javascript. Learn through building a fullstack project."
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/fullstack-app1');
        }}
        isMobileView={isMobile}
        duration="2 hours"
        dim={p && p !== 'fullstack-apps' && allowDim}
        shouldHighlight={p === 'fullstack-apps'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="Google Photos"
        projectDescription={`Recommended lesson to learn production processes. This is the 2nd installment of "fullstack development". `}
        shortDescription="Emphasis on software engineering principles: Agile methodologies, object oriented design, API design, CI/CD, testing, devOps, and more."
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/fullstack-app2');
        }}
        isMobileView={isMobile}
        duration="3 hours"
        dim={p && p !== 'fullstack-apps' && allowDim}
        shouldHighlight={p === 'fullstack-apps'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="** Graph Executor **"
        projectDescription={`A very important project that combines utilizing fundamental algorithms, analysing tradeoffs, and implementing detailed specs. Recommended if you have 
        a background in algorithms and data structures, otherwise I recommend completing some lessons in the "Get the Offer" module first.`}
        shortDescription="(KEY PROJECT) Develop important problem solving and problem reduction skills with this algorithmic project. Emphasis on tradeoff analysis. "
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/algo-app1');
        }}
        isMobileView={isMobile}
        duration="3 hours"
        dim={p && p !== 'algo-apps' && allowDim}
        shouldHighlight={p === 'algo-apps'}
        setDim={restrictDim}
      />
    </div>
  );

  const secondRow = (
    <div className={screenTooNarrow ? classes.column : classes.row}>
        <HoverCard
        projectTitle="Neural Network"
        projectDescription={`Introduces some mathematical concepts in linear algebra and calculus, and discretizes them with computer science. Emphasis on
        understanding the math behind the code, and how to apply it to real world problems.`}
        shortDescription="Math is to software engineering the same as grammar is to writing. Learn both while building a neural network with backpropagation."
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/algo-app2');
        }}
        isMobileView={isMobile}
        duration="2 hours"
        dim={p && p !== 'algo-apps' && allowDim}
        shouldHighlight={p === 'algo-apps'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="Combining Everything"
        projectDescription={`Another key skill in software engineering: gluing together different pieces of code to create a cohesive whole. If you
         followed good software engineering principles taught in the lessons, this project will be a breeze.`}
        shortDescription="Combine your implementations of Google Photos, Graph Executor and Neural Network, to build an end-to-end ML backed application."
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/combo-app');
        }}
        isMobileView={isMobile}
        duration="3 hours"
        dim={p && p !== 'combo-app' && allowDim}
        shouldHighlight={p === 'combo-app'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="Low Level Systems"
        projectDescription={`Get a sense of how different programming languages do things, cache localization, and how to take advantage of 
        hardware.`}
        shortDescription="Gain a deeper understanding of how computers work, and how to write code that runs most efficiently on hardware."
        buttonText="Start Project"
        buttonCallback={() => {
          history.push('/content/get-experience/lowlevel-app');
        }}
        isMobileView={isMobile}
        duration="2 hours"
        dim={p && p !== 'lowlevel-app' && allowDim}
        shouldHighlight={p === 'lowlevel-app'}
        setDim={restrictDim}
      />
    </div>
  );

  return (
    <div>
      <Button
        color="default"
        variant="contained"
        onClick={() => history.push('/content')}
        className={classes.button}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
          </g>
        </svg>
      </Button>

      {firstRow}
      {secondRow}
    </div>
  );
};

export const GetTheInterview = () => {
  const history = useHistory();
  const classes = useStyles();
  const screenTooNarrow = useIsMobile({
    width: 1085,
  });
  const isMobile = useIsMobile();
  const { p } = queryString.parse(window.location.search);
  const [allowDim, restrictDim] = useState(true);
  const firstRow = (
    <div className={screenTooNarrow ? classes.column : classes.row}>
        <HoverCard
        projectTitle="Cracking the OA"
        projectDescription={`Learn the ins and outs of the OA, some common pitfalls, common patterns to watch for, and how to maximize your chance of passing this automated stage.`}
        shortDescription="Companies usually auto-send OAs and use it as another data point along with your resume to determine interview eligibility."
        buttonText="Start Lesson"
        buttonCallback={() => {
          history.push('/content/get-interview/cracking-oa');
        }}
        isMobileView={isMobile}
        duration="1.5 hours"
        dim={p && p !== 'cracking-oa' && allowDim}
        shouldHighlight={p === 'cracking-oa'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="Optimize interview probability"
        projectDescription={`What can you do to boost interview probability? The answer is case by case, this module explores common cases depending on your experience level (L1 - L6) and the best move in your position.`}
        shortDescription="Remove ambiguity from the equation and learn the best things you can do to get the interview depending on your position (L1 - L6 exp)."
        buttonText="Start Lesson"
        buttonCallback={() => {
          history.push('/content/get-interview/build-best-resume');
        }}
        isMobileView={isMobile}
        duration="1.5 hours"
        dim={p && p !== 'resume-screening' && allowDim}
        shouldHighlight={p === 'resume-screening'}
        setDim={restrictDim}
      />
    </div>
  );
  return (
    <div>
      <Button
        color="default"
        variant="contained"
        onClick={() => history.push('/content')}
        className={classes.button}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
          </g>
        </svg>
      </Button>
      {firstRow}
    </div>
  );
};

export const GetTheOffer = () => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useIsMobile({
    width: 768,
  });
  const screenTooNarrow = useIsMobile({
    width: 1618,
  });
  const { p } = queryString.parse(window.location.search);
  const [allowDim, restrictDim] = useState(true);

  const firstRow = (
    <div className={screenTooNarrow ? classes.column : classes.row}>
        <HoverCard
        projectTitle="Fundamental data structures and algorithms"
        projectDescription={`Dive deep into the intracacies and tradeoff considerations of implementing common data structures and algorithms.`}
        shortDescription="The building blocks for understanding common interview patterns. Learn how important data structures and algorithms get implemented."
        buttonText="Start Lesson"
        buttonCallback={() => {
          history.push('/content/get-offer/fundamental-dsa');
        }}
        isMobileView={isMobile}
        duration="4 hours"
        dim={p && p !== 'fundamental-dsa' && allowDim}
        shouldHighlight={p === 'fundamental-dsa'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="Core coding interview patterns"
        projectDescription={`Take what you've learned about data structures and algorithms and apply them to common interview problem patterns.`}
        shortDescription="Don't memorize problems, learn to understand the patterns and how to apply them to new problems. This module will cover in-depth common patterns."
        buttonText="Start Lesson"
        buttonCallback={() => {
          history.push('/content/get-offer/coding-patterns');
        }}
        isMobileView={isMobile}
        duration="5 hours"
        dim={p && p !== 'coding-patterns' && allowDim}
        shouldHighlight={p === 'coding-patterns'}
        setDim={restrictDim}
      />
      <HoverCard
        projectTitle="System design interviews"
        projectDescription={`Explore in-depth the anatomy of system design interviews and how to approach them. Covering concepts like load balancing, caching, and more.`}
        shortDescription="Learn how to approach system design interviews and how to think about designing large scale systems."
        buttonText="Start Lesson"
        buttonCallback={() => {
          history.push('/content/get-offer/system-design');
        }}
        isMobileView={isMobile}
        duration="3 hours"
        dim={p && p !== 'system-design' && allowDim}
        shouldHighlight={p === 'system-design'}
        setDim={restrictDim}
      />
    </div>
  );
  return (
    <div>
      <Button
        color="default"
        variant="contained"
        onClick={() => history.push('/content')}
        className={classes.button}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
          </g>
        </svg>
      </Button>
      {firstRow}
    </div>
  );
};

export const FullStackApp1 = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['digital_flipbook_player']} />
);

export const FullStackApp2 = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['google_photos_clone']} />
);

export const AlgoApp1 = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['graph_executor']} />
);

export const AlgoApp2 = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['neural_network']} />
);

export const ComboApp = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['combination']} />
);

export const LowLevelApp = () => (
  <VideosView videosList={GET_THE_EXPERIENCE['low_level_systems']} />
);

export const CrackingOA = () => (
  <VideosView videosList={GET_THE_INTERVIEW['cracking_the_oa']} />
);

export const BuildBestResume = () => (
  <VideosView videosList={GET_THE_INTERVIEW['building_best_resume']} />
);

export const FundamentalDSA = () => (
  <VideosView videosList={GET_THE_OFFER['fundamental_data_structures_algorithms']} />
);

export const CodingPatterns = () => (
  <VideosView videosList={GET_THE_OFFER['core_coding_interview_patterns']} />
);

export const SystemDesign = () => (
  <VideosView videosList={GET_THE_OFFER['system_design']} />
);