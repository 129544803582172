/**
 *
 * @param resumeObj contains the resume data of the current logged in user.
 * @param template  the mapping object from the template -> string.
 * @returns the rendered resume template represented in latex as a string.
 */
export const render = (resumeObj: any, template: any) => {
  let order = resumeObj['order'];
  if ('override_order' in template) {
    order = template['override_order'];
  }
  let resumeContentTexStr = '';
  for (let section of ['profile'].concat(order)) {
    switch (section) {
      case 'profile':
        resumeContentTexStr += renderProfile(
          resumeObj['profile'],
          template['profile'],
        );
        break;
      case 'work_experience':
        resumeContentTexStr += renderWorkExperience(
          resumeObj['work_experience'],
          template['work_experience'],
        );
        break;
      case 'education':
        resumeContentTexStr += renderEducation(
          resumeObj['education'],
          template['education'],
        );
        break;
      case 'technical_skills':
        resumeContentTexStr += renderTechnicalSkills(
          resumeObj['technical_skills'],
          template['technical_skills'],
        );
        break;
      case 'projects':
        resumeContentTexStr += renderProjects(
          resumeObj['projects'],
          template['projects'],
        );
        break;
      case 'awards': 
        resumeContentTexStr += renderAwards(
          resumeObj['awards'],
          template['awards'],
        );
        break;
      case 'coursework': 
        resumeContentTexStr += renderCoursework(
          resumeObj['coursework'],
          template['coursework'],
        );
        break;
      default:
        break;
    }
  }
  return (
    template['base'].replaceAll('__main__', resumeContentTexStr) +
    template['tail']
  );
};

const renderProfile = (profile: any, template: any) => {
  if (!profile || !template) return '';
  return template
    .replaceAll('__full_name__', profile['__full_name__'])
    .replaceAll('__last_updated__', profile['__last_updated__'])
    .replaceAll('__email__', profile['__email__'])
    .replaceAll('__github__', profile['__github__'])
    .replaceAll('__linkedin__', profile['__linkedin__'])
    .replaceAll('__phone_number__', profile['__phone_number__'])
    .replaceAll('__personal_website__', profile['__personal_website__'])
    .replaceAll('__location__', profile['__location__']);
};

const renderWorkExperience = (workExperience: any, template: any) => {
  if (!workExperience || !template) return '';
  const wrapper = template['wrapper'];
  const positionsTemplate = template['position_template'];
  const bulletPointTemplate = template['bullet_point_template'];
  const dynamicLatexMap = template['dynamic_latex_map'] || {};

  let workExperienceStr = '';
  let index = 0;
  for (let work of workExperience) {
    let positionStr = positionsTemplate
      .replaceAll('__title__', work['__title__'])
      .replaceAll('__company__', work['__company__'])
      .replaceAll('__dates__', work['__dates__'])
      .replaceAll('__location__', work['__location__']);

    if (`__${index}__` in dynamicLatexMap) {
      positionStr = positionStr.replaceAll(
        `__i__`,
        dynamicLatexMap[`__${index}__`],
      );
    } else {
      positionStr = positionStr.replaceAll(`__i__`, '');
    }

    let bulletPointsStr = '';
    for (let bullet of work['bullet_points']) {
      bulletPointsStr += bulletPointTemplate.replaceAll('__index__', bullet);
    }
    positionStr = positionStr.replaceAll('__bullet_points__', bulletPointsStr);
    workExperienceStr += positionStr;
    index++;
  }
  return wrapper.replaceAll('__positions__', workExperienceStr);
};

const renderEducation = (education: any, template: any) => {
  if (!education || !template) return '';
  const wrapper = template['wrapper'];
  const schoolTemplate = template['school_template'];
  const bulletPointTemplate = template['bullet_point_template'];
  const dynamicLatexMap = template['dynamic_latex_map'] || {};

  let educationStr = '';
  let index = 0;
  for (let school of education) {
    let schoolStr = schoolTemplate
      .replaceAll('__school__', school['__school__'])
      .replaceAll('__degree__', school['__degree__'])
      .replaceAll('__dates__', school['__dates__'])
      .replaceAll('__location__', school['__location__'])
      .replaceAll('__grade__', school['__grade__']);

    if (`__${index}__` in dynamicLatexMap) {
      schoolStr = schoolStr.replaceAll(
        `__i__`,
        dynamicLatexMap[`__${index}__`],
      );
    } else {
      schoolStr = schoolStr.replaceAll(`__i__`, '');
    }

    let bulletPointsStr = '';
    for (let bullet of school['bullet_points']) {
      bulletPointsStr += bulletPointTemplate.replaceAll('__index__', bullet);
    }
    schoolStr = schoolStr.replaceAll('__bullet_points__', bulletPointsStr);
    educationStr += schoolStr;
    index++;
  }
  
  return wrapper.replaceAll('__schools__', educationStr)
};


const renderCoursework = (coursework: any, template: any) => {
  if (!coursework || !template) return '';
  const wrapper = template['wrapper'];
  let courseWorkStr = ''; 
  const courseWorkTemplate = template['coursework_template'];
  for (let course of coursework) {
    let courseStr = courseWorkTemplate
      .replaceAll('__course__', course['__course__'])
      .replaceAll('__descript__', course['__descript__']);
    courseWorkStr += courseStr;
  }
  return wrapper.replaceAll('__coursework__', courseWorkStr);
}


const renderTechnicalSkills = (technicalSkills: any, template: any) => {
  if (!technicalSkills || !template) return '';
  return template
    .replaceAll('__technologies__', technicalSkills['__technologies__'])
    .replaceAll(
      '__programming_languages__',
      technicalSkills['__programming_languages__'],
    );
};

const renderProjects = (projects: any, template: any) => {
  if (!projects || !template) return '';
  const wrapper = template['wrapper'];
  const projectTemplate = template['project_template'];
  const bulletPointTemplate = template['bullet_point_template'];
  const dynamicLatexMap = template['dynamic_latex_map'] || {};

  let projectsStr = '';
  let index = 0;
  for (let project of projects) {
    let projectStr = projectTemplate
      .replaceAll('__name__', project['__name__'])
      .replaceAll('__project_link__', project['__project_link__'])
      .replaceAll('__technologies__', project['__technologies__']);

    if (`__${index}__` in dynamicLatexMap) {
      projectStr = projectStr.replaceAll(
        `__i__`,
        dynamicLatexMap[`__${index}__`],
      );
    } else {
      projectStr = projectStr.replaceAll(`__i__`, '');
    }
    let bulletPointsStr = '';
    for (let bullet of project['bullet_points']) {
      bulletPointsStr += bulletPointTemplate.replaceAll('__index__', bullet);
    }
    projectStr = projectStr.replaceAll('__bullet_points__', bulletPointsStr);
    projectsStr += projectStr;
    index++;
  }
  return wrapper.replaceAll('__projects__', projectsStr);
};


const renderAwards = (awards: any, template: any) => {
  if (!awards || !template) return '';
  const wrapper = template['wrapper'];
  const awardTemplate = template['award_template'];
  const bulletPointTemplate = template['bullet_point_template'];
  const dynamicLatexMap = template['dynamic_latex_map'] || {};

  let awardsStr = '';
  let index = 0;
  for (let award of awards) {
    let awardStr = awardTemplate
      .replaceAll('__title__', award['__title__'])

    if (`__${index}__` in dynamicLatexMap) {
      awardStr = awardStr.replaceAll(
        `__i__`,
        dynamicLatexMap[`__${index}__`],
      );
    } else {
      awardStr = awardStr.replaceAll(`__i__`, '');
    }
    let bulletPointsStr = '';
    for (let bullet of award['bullet_points']) {
      bulletPointsStr += bulletPointTemplate.replaceAll('__index__', bullet);
    }
    awardStr = awardStr.replaceAll('__bullet_points__', bulletPointsStr);
    awardsStr += awardStr;
    index++;
  }
  return wrapper.replaceAll('__awards__', awardsStr);
}