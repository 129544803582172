import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../shared/Login/AuthContext';
import {
  LIMIT_SIGNUPS_FLAG,
  EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION,
} from '../config';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import LoginPopup from '../shared/Login/LoginPopup';
import { Card, CardActions, CardContent } from '@mui/material';
import check from '../assets/check.svg';
import { useIsMobile } from '../shared/UseIsMobile';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      margin: '30px',
      fontFamily: '"Nunito Sans", sans-serif',
    },
    expressWaitlistPurchaseCard: {
      margin: '30px',
      fontFamily: '"Nunito Sans", sans-serif',
      position: 'relative',
      padding: '0',
    },
    defaultCard: {
      margin: 'auto',
      fontFamily: '"Nunito Sans", sans-serif',
      width: '50vw',
    },
    title: {
      textAlign: 'center',
      fontFamily: '"Nunito Sans", sans-serif',
    },
  });
});

// Should just be the button that enables checkout.
const CheckoutView = (props: {
  token: string;
  disabled: boolean;
  product: string;
  buttonTxt?: string;
}) => {
  return (
    <form
      action={
        'api/stripe/create-checkout-session?token=' +
        props.token +
        '&product=' +
        props.product
      }
      method="POST"
    >
      <Button
        type="submit"
        id="checkout-button"
        color="success"
        variant="contained"
        disabled={props.disabled}
      >
        <b>{props.buttonTxt || 'Gain Unlimited Access'}</b>
      </Button>
    </form>
  );
};

const BulletPoint = (props: { descript: string }) => {
  return (
    <div style={{ display: 'flex' }}>
      <img src={check} alt="DONE" style={{ marginRight: '10px' }} />{' '}
      <h3>{props.descript}</h3>
    </div>
  );
};

const Banner = (props: { proExpiryDate: Date }) => {
  const isOnExpressWaitlist =
    new Date(props.proExpiryDate).getTime() ===
    new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION).getTime();
  return (
    <React.Fragment>
      <h1 style={{ fontSize: '40px' }}>Full Membership Pro Account</h1>
      <h1>
        {isOnExpressWaitlist ? (
          <span>
            <s>$99.99</s> → $69.99
          </span>
        ) : (
          '$99.99'
        )}{' '}
        one time payment
      </h1>
      <h2>By creating a free account, all affliated companies can see the resume you build. <br /> <u>With a Pro Account, you get:</u></h2>
      
      <BulletPoint descript="Customized Online Assessments (OAs) + take home projects from affiliated companies. Your scores are validated and sent." />
      {/* <BulletPoint descript="Validate Online Assessment + take home project scores with our internal process, and send results to interested companies." /> */}
      <BulletPoint descript="On demand support to help you land more interviews based on your saved resume " />
      <BulletPoint descript="Verified leetcode premium discount codes (10% - 40% off)" />
      {/* <BulletPoint descript="Internal tools to automate sending recruiter emails" /> */}
      <BulletPoint descript="Full resume builder features (everything that you would get if you enrolled in the priority waitlist)" />
      <BulletPoint descript="Higher visibility + prioritization of your resume with top firms we work with " />
      <BulletPoint descript="Unlimited access to all current and future content forever." />
    </React.Fragment>
  );
};

const LimitView = (props: { spots_available: number }) => {
  const { isLoggedIn, email, githubUsername, token, proExpirationDate } =
    useContext(AuthContext);

  const isOnExpressWaitlist =
    new Date(proExpirationDate).getTime() ===
    new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION).getTime();
  const spots = props.spots_available;
  const allowEnrollment = spots > 0;
  const classes = useStyles();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);

  const spotsBlock = (
    <div style={{ backgroundColor: 'black', borderRadius: '10px', width: '50vw' }}>
      <h2 style={{ color: 'red', margin: '10px' }}>
        {spots} spots remaining -{' '}
        <span style={{ fontSize: '18px', color: 'white' }}>
          {' '}
          Spots are limited due to negotiations with the leetcode team to offer discount codes, 
          and role openings with the companies we are in contact with.
        </span>
      </h2>
    </div>
  );

  const loggedIn = (
    <div>
      <Card
        className={isOnExpressWaitlist ? classes.defaultCard : classes.card}
      >
        <CardContent>
          <Banner proExpiryDate={proExpirationDate} />
          {!isOnExpressWaitlist && spotsBlock}
          {!allowEnrollment ? (
            <React.Fragment>
              <div>
                {email === 'N/A' ? (
                  <h3>
                    Hi {githubUsername}, you are missing an email address, in
                    order to be added to the waitlist, please click on your
                    profile to update.
                  </h3>
                ) : (
                  <h3>
                    {email} has been added to the{' '}
                    {isOnExpressWaitlist && 'Priority '} waitlist. Once spots
                    open, expect an update!
                  </h3>
                )}
              </div>
              <CheckoutView
                token={token}
                disabled={true}
                product={
                  isOnExpressWaitlist
                    ? 'member_enrollment_discount'
                    : 'member_enrollment'
                }
              />
            </React.Fragment>
          ) : (
            <CheckoutView
              token={token}
              disabled={false}
              product={
                isOnExpressWaitlist
                  ? 'member_enrollment_discount'
                  : 'member_enrollment'
              }
            />
          )}
        </CardContent>
      </Card>
    </div>
  );

  const notLoggedIn = (
    <Card className={classes.card}>
      <CardContent>
        <Banner proExpiryDate={proExpirationDate} />
        {!isOnExpressWaitlist && spotsBlock}
        {!allowEnrollment && (
          <h3>
            All spots have been filled.{' '}
            <a
              href="#"
              onClick={() => setLoginPopupOpen(true)}
              style={{ color: 'green' }}
            >
              Join the waitlist
            </a>{' '}
            by signing up with your Google account or Github account (with a
            public email visible).
          </h3>
        )}
        <Button
          variant="contained"
          color="success"
          onClick={() => setLoginPopupOpen(true)}
          disabled={!allowEnrollment}
        >
          <b>Gain Unlimited Access</b>
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <React.Fragment>
      <LoginPopup
        open={loginPopupOpen}
        handleClose={() => {
          setLoginPopupOpen(false);
        }}
      />
      {isLoggedIn ? loggedIn : notLoggedIn}
    </React.Fragment>
  );
};

const DefaultView = () => {
  const { isLoggedIn, token, proExpirationDate } = useContext(AuthContext);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const classes = useStyles();
  const isOnExpressWaitlist =
    new Date(proExpirationDate).getTime() ===
    new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION).getTime();

  const loggedIn = (
    <Card className={classes.defaultCard}>
      <CardContent>
        <Banner proExpiryDate={proExpirationDate} />
        <CheckoutView
          token={token}
          disabled={false}
          product={
            isOnExpressWaitlist
              ? 'member_enrollment_discount'
              : 'member_enrollment'
          }
        />
      </CardContent>
    </Card>
  );

  const notLoggedIn = (
    <Card className={classes.defaultCard}>
      <CardContent>
        <Banner proExpiryDate={proExpirationDate} />
        <Button
          variant="contained"
          color="success"
          onClick={() => setLoginPopupOpen(true)}
        >
          <b>Gain Unlimited Access</b>
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <React.Fragment>
      <LoginPopup
        open={loginPopupOpen}
        handleClose={() => {
          setLoginPopupOpen(false);
        }}
      />
      {isLoggedIn ? loggedIn : notLoggedIn}
    </React.Fragment>
  );
};

const ExpressWaitlist = () => {
  const classes = useStyles();
  const { isLoggedIn, token } = useContext(AuthContext);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  return (
    <React.Fragment>
      <LoginPopup
        open={loginPopupOpen}
        handleClose={() => {
          setLoginPopupOpen(false);
        }}
      />
      <Card className={classes.expressWaitlistPurchaseCard}>
        <CardContent>
          <h1 style={{ fontSize: '40px' }}>Resume Builder + Priority Waitlist</h1>
          <h1>$19.99 - one time payment</h1>
          <h2>Unlock the full resume builder. Join the priority waitlist.</h2>
          <BulletPoint descript="Access to all premium templates (all templates used in the past to land interviews)" />
          <BulletPoint descript="Ability to export templates as raw .tex files + accompanying assets" />
          <BulletPoint descript="On-demand technical support + new resume template suggestions" />
          <div style={{ display: 'flex' }}>
            <img src={check} alt="DONE" style={{ marginRight: '10px' }} />{' '}
            <h3>
              Discount on full membership (<s>$99.99</s> → $69.99) when spots open
            </h3>
          </div>
          {/* <div style={{ backgroundColor: 'black', borderRadius: '10px' }}>
            <h2 style={{ margin: '10px' }}>
              <span style={{ fontSize: '18px', color: 'white' }}>
                {' '}
                Again, for each person
                that enrolls from the waitlist, I will reach out to get a better
                sense of how the content in this course will help you with your
                career goals.
              </span>
            </h2>
          </div> */}
          <h3>Enrollment in the Priority waitlist will close once spots open.</h3>
        </CardContent>
        <br></br>
        <br></br>
        <CardActions>
          {isLoggedIn ? (
            <div
              style={{
                position: 'absolute',
                bottom: '25px',
                marginLeft: '7.5px',
              }}
            >
              <CheckoutView
                token={token}
                disabled={false}
                product={'express_waitlist'}
                buttonTxt={'Join the Priority waitlist'}
              />
            </div>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => setLoginPopupOpen(true)}
              style={{
                position: 'absolute',
                bottom: '25px',
                marginLeft: '7.5px',
              }}
            >
              <b>Join the priority waitlist</b>
            </Button>
          )}
        </CardActions>
      </Card>
      <div></div>
    </React.Fragment>
  );
};

const Enroll = () => {
  const [spotsAvailable, setSpotsAvailable] = useState(0);
  const classes = useStyles();
  const isMobile = useIsMobile(); // For UI stuff later.
  const { isLoggedIn, proExpirationDate } = useContext(AuthContext);
  const isNOTOnExpressWaitlist =
    !isLoggedIn ||
    new Date(proExpirationDate).getTime() !==
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION).getTime();

  useEffect(() => {
    // If we're not limiting sign ups, do nothing.
    if (!LIMIT_SIGNUPS_FLAG) return;
    // Query for spots available, pass that to Limit View.
    const fetchSpotsAvailable = async () => {
      try {
        const response = await fetch('/spots-available');
        const data = await response.json();
        if (!response.ok) {
          console.log('Failed to fetch spots available.');
          throw new Error(
            'Failed to fetch spots available. Details: ' + data['message'],
          );
        }
        const spotsAvailable = data['spots_available'];
        setSpotsAvailable(spotsAvailable);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSpotsAvailable();
  }, []);

  return (
    <React.Fragment>
      <h1 className={classes.title}>
        Helping you land interviews at top firms. 
      </h1>
      <div
        style={{
          display:
            LIMIT_SIGNUPS_FLAG && !isMobile && isNOTOnExpressWaitlist
              ? 'flex'
              : 'block',
        }}
      >
        {!LIMIT_SIGNUPS_FLAG && <DefaultView />}
        {LIMIT_SIGNUPS_FLAG && <LimitView spots_available={spotsAvailable} />}
        {LIMIT_SIGNUPS_FLAG &&
          spotsAvailable === 0 &&
          isNOTOnExpressWaitlist && <ExpressWaitlist />}
      </div>
    </React.Fragment>
  );
};

export default Enroll;
