import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { tabs } from './Tabs';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logo_mobile.svg';
import { AuthContext } from '../Login/AuthContext';

const useStyles = makeStyles({
  list: {
    width: 320,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    maxHeight: 80,
    margin: 5,
  },
});

const MobileNav = (props: any) => {
  const menuStyle = props.menuStyle;
  const menuClassName = props.menuClassName;
  const classes = useStyles();
  const { isLoggedIn, proExpirationDate } = React.useContext(AuthContext);
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (open: boolean) => (event: React.MouseEvent) => {
    setState({ left: open });
  };
  

  // content
  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      className={classes.fullList}
      style={{
        textAlign: 'center',
      }}
    >
      <NavLink to={'/'}>
        <img
          src={logo}
          alt="logo"
          className={classes.logo + ' hvr-grow-rotate'}
          id="logo"
        />
      </NavLink>
      <Divider />
      <List>
        {tabs.map((tab, index) => {
          return (
            <ListItem key={tab.url}>
              <Typography variant="subtitle1" className={props.title}>
                <NavLink to={tab.url} className={'dark not-highlighted'}>
                  {tab.text}
                </NavLink>
              </Typography>
            </ListItem>
          );
        })}
        {isLoggedIn && new Date() < new Date(proExpirationDate) ? (
          <ListItem key={'next-steps'}>
            <Typography variant="subtitle1" className={props.title}>
              <NavLink to={'next-steps'} className={'dark not-highlighted'}>
                <b>
                  <i>NEXT STEPS</i>
                </b>
              </NavLink>
            </Typography>
          </ListItem>
        ) : (
          <ListItem key={'enroll'}>
            <Typography variant="subtitle1" className={props.title}>
              <NavLink to={'enroll'} className={'dark not-highlighted'}>
                <b>
                  <i>ENROLL</i>
                </b>
              </NavLink>
            </Typography>
          </ListItem>
        )}
      </List>
    </div>
  );
  // end content

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        style={menuStyle}
        className={menuClassName}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={state.left} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};

export default MobileNav;
