import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../shared/Login/AuthContext';
import { Card } from '@mui/material';
import {
  makeStyles,
  Theme,
  createStyles,
  CardContent,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      margin: '30px',
      fontFamily: '"Nunito Sans", sans-serif',
    },
    title: {
      textAlign: 'center',
      fontFamily: '"Nunito Sans", sans-serif',
    },
  });
});

const NextSteps = () => {
  const {
    isLoggedIn,
    token,
    proExpirationDate,
    logout,
    login,
    email,
    name,
    githubUsername,
    accountType,
    image,
  } = useContext(AuthContext);
  const [privateDisordLink, setPrivateDiscordLink] = useState('');
  const [jackDiscordName, setJackDiscordName] = useState('');
  const [privateEmail, setPrivateEmail] = useState('');
  const classes = useStyles();
  useEffect(() => {
    if (!isLoggedIn || !token) {
      return;
    }
    const render = async () => {
      console.log('Rendering...');
      try {
        const query = new URLSearchParams(window.location.search);
        const refresh = query.get('refresh');
        if (refresh) {
          console.log('Refreshing...');
          // Fetch new token with existing token.
          const response = await fetch('/api/util/refresh', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to refresh token');
          }
          const data = await response.json();
          const newToken = data['new_token'];
          const new_proExpirationDate = data['new_proExpirationDate'];
          login(
            newToken,
            null,
            email,
            githubUsername,
            accountType,
            name,
            new_proExpirationDate,
            image,
          );
          window.location.href = '/next-steps';
        } else if (new Date() >= new Date(proExpirationDate)) {
          console.log('Non pro member, no refresh query parameter');
          throw new Error('Pro membership expired');
        } else {
          console.log('Pro member, welcome! Fetching private discord link...');
          // Assume logged in user is a pro member.
          const response = await fetch('/api/content/contact', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          setPrivateDiscordLink(data['discord_url']);
          setJackDiscordName(data['discord_name']);
          setPrivateEmail(data['private_email']);
        }
      } catch (err) {
        // Logout and refresh.
        console.log(err);
        alert('Please log back in.');
        logout();
        window.location.href = '/';
      }
    };
    render();
  }, [isLoggedIn]);

  const page = (
    <React.Fragment>
      <h1 className={classes.title}>Next Steps</h1>
      <Card variant="elevation" className={classes.card}>
        <CardContent>
          <h3>Now that you're a member, please feel free to reach out (contact info below) and I'd be happy to learn more about your situation and calibrate the course to maximize value for you.</h3>
        </CardContent>
      </Card>
      <Card variant="elevation" className={classes.card}>
        <CardContent>
          <h2><b>Best way to contact me - message me directly on discord: </b><a href={privateDisordLink} target="_blank">Discord: {jackDiscordName}</a></h2>
          <h3>Private email: {privateEmail} or jackhe3345@gmail.com</h3>
          <h3>Linkedin (might not reply, I get a lot of messages): <a href={"https://linkedin.com/in/jackhetech"} target="_blank">Jack He</a></h3>
        </CardContent>
      </Card>
    </React.Fragment>
  );

  // Scenario:
  // user is not logged in - render nothing, fetch nothing.
  // user is logged in but not a member - render nothing, but if there is a url refresh param, update token and try re-rendering. Otherwise, logout.
  // user is logged in and is a member - render page, fetch data.

  return (
    <React.Fragment>
      {isLoggedIn && new Date() < new Date(proExpirationDate) ? (
        page
      ) : (
        <h1>Become a member to view next steps.</h1>
      )}
    </React.Fragment>
  );
};

export default NextSteps;
