export const fetchTemplate = async (
  path: string,
  token: string,
  callback: any,
) => {
  try {
    const response = await fetch(path, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    if (!response.ok) throw new Error('Failed to fetch template. ');
    const data = await response.json();
    callback(data['template']);
  } catch (err) {
    alert('Failed to grab template, err: ' + err);
  }
};

export const combine = (slices: Array<any>, N: Number) => {
  let combined: any = {};
  let array: Array<any> = new Array(N);
  for (let slice of slices) {
    array[slice.__merge_order__] = slice;
  }
  for (let orderedSlice of array) {
    for (let key in orderedSlice) {
        if (key !== '__merge_order__') {
            if (key in combined) {
                combined[key] = combined[key].concat(orderedSlice[key])
            } else {
                combined[key] = orderedSlice[key];
            }
        }
    }
  }
  return combined
}

/*
 * Converts from array of { __course__: string, __descript__: string } to string
 */
export const courseWorkArrToString = (courseWorkArr: Array<any>) => {
  let courseWorkStr = '';
  for (let course of courseWorkArr) {
    courseWorkStr += '>>>' + course.__course__ + ':' + course.__descript__ + ';\n\n';
  }
  return courseWorkStr.replaceAll('\\', '');
};

/*
 * Converts from string to array of { __course__: string, __descript__: string }
 */
export const courseWorkStrToArr = (courseWorkStr: string) => {
  courseWorkStr = courseWorkStr
    .replaceAll('\\', '')
    .replaceAll('$', '\\$')
    .replaceAll('%', '\\%');

  const regex = />>>(.+?);/g;
  const matches = courseWorkStr.match(regex);
  const courseWorkArr = [];
  if (matches) {
    const content = matches.map((match) => match.replace(/>>>(.+?);/g, '$1'));
    for (let match of content) {
      let courseTuple = match.split(':');
      if (courseTuple.length === 2) {
        courseWorkArr.push({
          __course__: courseTuple[0],
          __descript__: courseTuple[1],
        });
      }
    }
    return courseWorkArr;
  }
  return [];
};

export const RUSSELL_CLS = `
%-------------------------------------------------------------------------------
%                Identification
%-------------------------------------------------------------------------------
\\ProvidesClass{russell}[2017/02/05 v1.6.1 russell Curriculum Vitae Class]
\\NeedsTeXFormat{LaTeX2e}


%-------------------------------------------------------------------------------
%                Class options
%
% (need to be done before the external package loading, for example because
% we need \\paperwidth, \\paperheight and \\@ptsize to be defined before loading
% geometry and fancyhdr)
%-------------------------------------------------------------------------------
% Options for draft or final
\\DeclareOption{draft}{\\setlength\\overfullrule{5pt}}
\\DeclareOption{final}{\\setlength\\overfullrule{0pt}}
% Inherit options of article
\\DeclareOption*{%
  \\PassOptionsToClass{\\CurrentOption}{article}
}
\\ProcessOptions\\relax
\\LoadClass{article}


%-------------------------------------------------------------------------------
%                3rd party packages
%-------------------------------------------------------------------------------
% Needed to make fixed length table
\\RequirePackage{array}
% Needed to handle list environment
\\RequirePackage{enumitem}
% Needed to handle text alignment
\\RequirePackage{ragged2e}
% Needed to configure page layout
\\RequirePackage{geometry}
% Needed to make header & footer effeciently
\\RequirePackage{fancyhdr}
% Needed to manage colors
\\RequirePackage{xcolor}
% Needed to use \\ifxetex-\\else-\\fi statement
\\RequirePackage{ifxetex}
% Needed to use \\if-\\then-\\else statement
\\RequirePackage{xifthen}
% Needed to use a toolbox of programming tools
\\RequirePackage{etoolbox}
% Needed to change line spacing in specific environment
\\RequirePackage{setspace}
% Needed to manage fonts
\\RequirePackage[quiet]{fontspec}
% To support LaTeX quoting style
\\defaultfontfeatures{Ligatures=TeX}
% Needed to manage math fonts
\\RequirePackage{unicode-math}
% Needed to use icons from font-awesome
\\RequirePackage{fontawesome5}
\\RequirePackage{roboto}
\\RequirePackage[default,opentype]{sourcesanspro}
% Needed for the photo ID
\\RequirePackage[skins]{tcolorbox}
% Needed to deal a paragraphs
\\RequirePackage{parskip}
% Needed to deal hyperlink
\\RequirePackage[hidelinks,unicode,pdfpagelabels=false]{hyperref}
\\hypersetup{%
  pdftitle={},
  pdfauthor={},
  pdfsubject={},
  pdfkeywords={}
}
\\RequirePackage{bookmark}

%-------------------------------------------------------------------------------
%                Configuration for directory locations
%-------------------------------------------------------------------------------
% Configure an optional directory location for fonts(default: 'fonts/')
% Not required anymore but left in place for backward compatability.
\\newcommand*{\\fontdir}[1][fonts/]{\\def\\@fontdir{#1}}

%-------------------------------------------------------------------------------
%                Configuration for layout
%-------------------------------------------------------------------------------
%% Page Layout
% Configure page margins with geometry
\\geometry{left=2.0cm, top=1.5cm, right=2.0cm, bottom=2.0cm, footskip=.5cm}

%% Header & Footer
% Set offset to each header and footer
\\fancyhfoffset{0em}
% Remove head rule
\\renewcommand{\\headrulewidth}{0pt}
% Clear all header & footer fields
\\fancyhf{}
% Enable if you want to make header or footer using fancyhdr
\\pagestyle{fancy}


%-------------------------------------------------------------------------------
%                Configuration for colors
%-------------------------------------------------------------------------------
% Gray-scale colors
\\definecolor{white}{HTML}{FFFFFF}
\\definecolor{black}{HTML}{000000}
\\definecolor{darkgray}{HTML}{333333}
\\definecolor{gray}{HTML}{5D5D5D}
\\definecolor{lightgray}{HTML}{999999}
% Basic colors
\\definecolor{green}{HTML}{C2E15F}
\\definecolor{orange}{HTML}{FDA333}
\\definecolor{purple}{HTML}{D3A4F9}
\\definecolor{red}{HTML}{FB4485}
\\definecolor{blue}{HTML}{6CE0F1}
% Text colors
\\definecolor{darktext}{HTML}{414141}
\\colorlet{text}{darkgray}
\\colorlet{graytext}{gray}
\\colorlet{lighttext}{lightgray}
\\colorlet{sectiondivider}{gray}
% russell colors
\\definecolor{russell-emerald}{HTML}{00A388}
\\definecolor{russell-skyblue}{HTML}{0395DE}
\\definecolor{russell-red}{HTML}{DC3522}
\\definecolor{russell-purple}{HTML}{A32CC4}
\\definecolor{russell-pink}{HTML}{EF4089}
\\definecolor{russell-orange}{HTML}{FF6138}
\\definecolor{russell-nephritis}{HTML}{27AE60}
\\definecolor{russell-concrete}{HTML}{95A5A6}
\\definecolor{russell-darknight}{HTML}{131A28}
\\definecolor{russell-black}{HTML}{000000}
\\colorlet{russell}{russell-red}

% Boolean value to switch section color highlighting
\\newbool{acvSectionColorHighlight}
\\setbool{acvSectionColorHighlight}{true}

% russell section color
\\def\\@sectioncolor#1#2#3{%
  \\ifbool{acvSectionColorHighlight}{{\\color{russell}#1#2#3}}{#1#2#3}%
}


%-------------------------------------------------------------------------------
%                Configuration for fonts
%-------------------------------------------------------------------------------
\\newcommand*{\\headerfont}{\\roboto}
\\newcommand*{\\headerfontlight}{\\robotolight}
\\newcommand*{\\footerfont}{\\sourcesanspro}
\\newcommand*{\\bodyfont}{\\sourcesanspro}
\\newcommand*{\\bodyfontlight}{\\sourcesansprolight}

%-------------------------------------------------------------------------------
%                Configuration for styles
%-------------------------------------------------------------------------------
% Configure styles for each CV elements
% For fundamental structures
\\newcommand*{\\headerfirstnamestyle}[1]{{\\fontsize{20pt}{1em}\\headerfont\\color{text} #1}}
\\newcommand*{\\headerlastnamestyle}[1]{{\\fontsize{20pt}{1em}\\headerfont\\color{text} #1}}
\\newcommand*{\\headerpositionstyle}[1]{{\\fontsize{7.6pt}{1em}\\bodyfont\\scshape\\color{russell} #1}}
\\newcommand*{\\headeraddressstyle}[1]{{\\fontsize{8pt}{1em}\\headerfont\\itshape\\color{lighttext} #1}}
\\newcommand*{\\headersocialstyle}[1]{{\\fontsize{6.8pt}{1em}\\headerfont\\color{text} #1}}
\\newcommand*{\\headerquotestyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\itshape\\color{darktext} #1}}
\\newcommand*{\\footerstyle}[1]{{\\fontsize{8pt}{1em}\\footerfont\\scshape\\color{lighttext} #1}}
\\newcommand*{\\sectionstyle}[1]{{\\fontsize{16pt}{1em}\\bodyfont\\bfseries\\color{text}\\@sectioncolor #1}}
\\newcommand*{\\subsectionstyle}[1]{{\\fontsize{12pt}{1em}\\bodyfont\\scshape\\textcolor{text}{#1}}}
\\newcommand*{\\paragraphstyle}{\\fontsize{9pt}{1em}\\bodyfontlight\\upshape\\color{text}}

% For elements of entry
\\newcommand*{\\entrytitlestyle}[1]{{\\fontsize{10pt}{1em}\\bodyfont\\bfseries\\color{darktext} #1}}
\\newcommand*{\\entrypositionstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\color{graytext} #1}}
\\newcommand*{\\entrydatestyle}[1]{{\\fontsize{8pt}{1em}\\bodyfontlight\\slshape\\color{graytext} #1}}
\\newcommand*{\\entrylocationstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfontlight\\slshape\\color{russell} #1}}
\\newcommand*{\\descriptionstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfontlight\\upshape\\color{text} #1}}

% For elements of subentry
\\newcommand*{\\subentrytitlestyle}[1]{{\\fontsize{8pt}{1em}\\bodyfont\\mdseries\\color{graytext} #1}}
\\newcommand*{\\subentrypositionstyle}[1]{{\\fontsize{7pt}{1em}\\bodyfont\\scshape\\color{graytext} #1}}
\\newcommand*{\\subentrydatestyle}[1]{{\\fontsize{7pt}{1em}\\bodyfontlight\\slshape\\color{graytext} #1}}
\\newcommand*{\\subentrylocationstyle}[1]{{\\fontsize{7pt}{1em}\\bodyfontlight\\slshape\\color{russell} #1}}
\\newcommand*{\\subdescriptionstyle}[1]{{\\fontsize{8pt}{1em}\\bodyfontlight\\upshape\\color{text} #1}}

% For elements of honor
\\newcommand*{\\honortitlestyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\color{graytext} #1}}
\\newcommand*{\\honorpositionstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\bfseries\\color{darktext} #1}}
\\newcommand*{\\honordatestyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\color{graytext} #1}}
\\newcommand*{\\honorlocationstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfontlight\\slshape\\color{russell} #1}}

% For elements of skill
\\newcommand*{\\skilltypestyle}[1]{{\\fontsize{10pt}{1em}\\bodyfont\\bfseries\\color{darktext} #1}}
\\newcommand*{\\skillsetstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfontlight\\color{text} #1}}

% For elements of the cover letter
\\newcommand*{\\lettersectionstyle}[1]{{\\fontsize{14pt}{1em}\\bodyfont\\bfseries\\color{text}\\@sectioncolor #1}}
\\newcommand*{\\recipientaddressstyle}[1]{{\\fontsize{9pt}{1em}\\bodyfont\\scshape\\color{graytext} #1}}
\\newcommand*{\\recipienttitlestyle}[1]{{\\fontsize{11pt}{1em}\\bodyfont\\bfseries\\color{darktext} #1}}
\\newcommand*{\\lettertitlestyle}[1]{{\\fontsize{10pt}{1em}\\bodyfontlight\\bfseries\\color{darktext} \\underline{#1}}}
\\newcommand*{\\letterdatestyle}[1]{{\\fontsize{9pt}{1em}\\bodyfontlight\\slshape\\color{graytext} #1}}
\\newcommand*{\\lettertextstyle}{\\fontsize{10pt}{1.4em}\\bodyfontlight\\upshape\\color{graytext}}
\\newcommand*{\\letternamestyle}[1]{{\\fontsize{10pt}{1em}\\bodyfont\\bfseries\\color{darktext} #1}}
\\newcommand*{\\letterenclosurestyle}[1]{{\\fontsize{10pt}{1em}\\bodyfontlight\\slshape\\color{lighttext} #1}}


%-------------------------------------------------------------------------------
%                Commands for personal information
%-------------------------------------------------------------------------------
% Define photo ID
% Usage: \\photo[circle|rectangle,edge|noedge,left|right]{<path-to-image>}
\\newcommand{\\photo}[2][circle,edge,left]{%
  \\def\\@photo{#2}
  \\@for\\tmp:=#1\\do{%
    \\ifthenelse{\\equal{\\tmp}{circle} \\or \\equal{\\tmp}{rectangle}}%
      {\\let\\@photoshape\\tmp}{}%
    \\ifthenelse{\\equal{\\tmp}{edge} \\or \\equal{\\tmp}{noedge}}%
      {\\let\\@photoedge\\tmp}{}%
    \\ifthenelse{\\equal{\\tmp}{left} \\or \\equal{\\tmp}{right}}%
      {\\let\\@photoalign\\tmp}{}%
  }%
}
\\def\\@photoshape{circle}
\\def\\@photoedge{edge}
\\def\\@photoalign{left}

% Define writer's name
% Usage: \\name{<firstname>}{<lastname>}
% Usage: \\firstname{<firstname>}
% Usage: \\lastname{<lastname>}
% Usage: \\familyname{<familyname>}
\\newcommand*{\\name}[2]{\\def\\@firstname{#1}\\def\\@lastname{#2}}
\\newcommand*{\\firstname}[1]{\\def\\@firstname{#1}}
\\newcommand*{\\lastname}[1]{\\def\\@lastname{#1}}
\\newcommand*{\\familyname}[1]{\\def\\@lastname{#1}}
\\def\\@familyname{\\@lastname}

% Define writer's address
% Usage: \\address{<address>}
\\newcommand*{\\address}[1]{\\def\\@address{#1}}

% Define writer's position
% Usage: \\position{<position>}
\\newcommand*{\\position}[1]{\\def\\@position{#1}}

% Defines writer's mobile (optional)
% Usage: \\mobile{<mobile number>}
\\newcommand*{\\mobile}[1]{\\def\\@mobile{#1}}

% Defines writer's email (optional)
% Usage: \\email{<email address>}
\\newcommand*{\\email}[1]{\\def\\@email{#1}}

% Defines writer's date of birth (optional)
% Usage: \\dateofbirth{<date>}
\\newcommand*{\\dateofbirth}[1]{\\def\\@dateofbirth{#1}}

% Defines writer's homepage (optional)
% Usage: \\homepage{<url>}
\\newcommand*{\\homepage}[1]{\\def\\@homepage{#1}}

% Defines writer's github (optional)
% Usage: \\github{<github-nick>}
\\newcommand*{\\github}[1]{\\def\\@github{#1}}

% Defines writer's gitlab (optional)
% Usage: \\gitlab{<gitlab-nick>}
\\newcommand*{\\gitlab}[1]{\\def\\@gitlab{#1}}

% Defines writer's bitbucket (optional)
% Usage: \\bitbucket{<bitbucket-nick>}
\\newcommand*{\\bitbucket}[1]{\\def\\@bitbucket{#1}}

% Defines writer's stackoverflow profile (optional)
% Usage: \\stackoverflow{<so userid>}{<so username>}
%   e.g.https://stackoverflow.com/users/123456/sam-smith
%       would be \\stackoverflow{123456}{sam-smith}
\\newcommand*{\\stackoverflow}[2]{\\def\\@stackoverflowid{#1}\\def\\@stackoverflowname{#2}}

% Defines writer's linked-in (optional)
% Usage: \\linkedin{<linked-in-nick>}
\\newcommand*{\\linkedin}[1]{\\def\\@linkedin{#1}}

% Defines writer's orcid (optional)
% Usage: \\orcid{<orcid-num>}
\\newcommand*{\\orcid}[1]{\\def\\@orcid{#1}}

% Defines writer's twitter (optional)
% Usage: \\twitter{<twitter handle>}
\\newcommand*{\\twitter}[1]{\\def\\@twitter{#1}}

% Defines writer's Mastodon (optional)
% Usage: \\mastodon{<instance>}{<mastodon-nick>}
\\newcommand*{\\mastodon}[2]{\\def\\@mastodoninstance{#1}\\def\\@mastodonname{#2}}

% Defines writer's resarchgate (optional)
% Usage: \\researchgate{<researchgate-account>}
\\newcommand*{\\researchgate}[1]{\\def\\@researchgate{#1}}

% Defines writer's skype (optional)
% Usage: \\skype{<skype account>}
\\newcommand*{\\skype}[1]{\\def\\@skype{#1}}

% Defines writer's reddit (optional)
% Usage: \\reddit{<reddit account>}
\\newcommand*{\\reddit}[1]{\\def\\@reddit{#1}}

% Defines writer's xing (optional)
% Usage: \\xing{<xing name>}
\\newcommand*{\\xing}[1]{\\def\\@xing{#1}}

% Defines writer's medium profile (optional)
% Usage: \\medium{<medium account>}
\\newcommand*{\\medium}[1]{\\def\\@medium{#1}}

% Defines writer's kaggle (optional)
% Usage: \\kaggle{<kaggle handle>}
\\newcommand*{\\kaggle}[1]{\\def\\@kaggle{#1}}

% Defines writer's google scholar profile (optional)
% Usage: \\googlescholar{<googlescholar userid>}{<googlescholar username>}
% e.g.https://scholar.google.co.uk/citations?user=wpZDx1cAAAAJ
% would be \\googlescholar{wpZDx1cAAAAJ}{Name-to-display-next-icon}
% If 'googlescholar-name' is not provided than it defaults to
% '\\firstname \\lastname'
\\newcommand*{\\googlescholar}[2]{%
  \\def\\@googlescholarid{#1}%
  \\ifthenelse{\\equal{#2}{}}{%
    \\def\\@googlescholarname{\\@firstname~\\@lastname}%
  }{%
    \\def\\@googlescholarname{#2}%
  }%
}

% Defines writer's extra information (optional)
% Usage: \\extrainfo{<extra information>}
\\newcommand*{\\extrainfo}[1]{\\def\\@extrainfo{#1}}

% Defines writer's quote (optional)
% Usage: \\quote{<quote>}
\\renewcommand*{\\quote}[1]{\\def\\@quote{#1}}

% Defines recipient's information (cover letter only)
% Usage: \\recipient{<recipient name>}{<recipient address>}
% Usage: \\recipientname{<recipient name>}
% Usage: \\recipientaddress{<recipient address>}
\\newcommand*{\\recipient}[2]{\\def\\@recipientname{#1}\\def\\@recipientaddress{#2}}
\\newcommand*{\\recipientname}[1]{\\def\\@recipientname{#1}}
\\newcommand*{\\recipientaddress}[1]{\\def\\@recipientaddress{#1}}

% Defines the title for letter (cover letter only, optional)
% Usage: \\lettertitle{<title>}
\\newcommand*{\\lettertitle}[1]{\\def\\@lettertitle{#1}}

% Defines the date for letter (cover letter only)
% Usage: \\letterdate{<date>}
\\newcommand*{\\letterdate}[1]{\\def\\@letterdate{#1}}

% Defines a message of opening for letter (cover letter only)
% Usage: \\letteropening{<message>}
\\newcommand*{\\letteropening}[1]{\\def\\@letteropening{#1}}

% Defines a message of closing for letter (cover letter only)
% Usage: \\letterclosing{<message>}
\\newcommand*{\\letterclosing}[1]{\\def\\@letterclosing{#1}}

% Defines an enclosure for letter (cover letter only, optional)
% Usage: \\letterenclosure[<enclosure name>]{<enclosure>}
\\newcommand*{\\letterenclname}[1][Enclosure]{\\def\\@letterenclname{#1}}
\\newcommand*{\\letterenclosure}[2][]{%
  % if an optional argument is provided, use it to redefine \\enclname
  \\ifthenelse{\\equal{#1}{}}{}{\\def\\@letterenclname{#1}}
  \\def\\@letterenclosure{#2}
}


%-------------------------------------------------------------------------------
%                Commands for extra
%-------------------------------------------------------------------------------
%% Define helper macros a user can change easily
% Header
\\newcommand{\\acvHeaderNameDelim}{\\space}
\\newcommand{\\acvHeaderAfterNameSkip}{.4mm}
\\newcommand{\\acvHeaderAfterPositionSkip}{.4mm}
\\newcommand{\\acvHeaderAfterAddressSkip}{-.5mm}
\\newcommand{\\acvHeaderIconSep}{\\space}
\\newcommand{\\acvHeaderSocialSep}{\\quad\\textbar\\quad}
\\newcommand{\\acvHeaderAfterSocialSkip}{6mm}
\\newcommand{\\acvHeaderAfterQuoteSkip}{5mm}

% Others
\\newcommand{\\acvSectionTopSkip}{3mm}
\\newcommand{\\acvSectionContentTopSkip}{2.5mm}


%-------------------------------------------------------------------------------
%                Commands for utilities
%-------------------------------------------------------------------------------
% Use to align an element of tabular table
\\newcolumntype{L}[1]{>{\\raggedright\\let\\newline\\\\\\arraybackslash\\hspace{0pt}}m{#1}}
\\newcolumntype{C}[1]{>{\\centering\\let\\newline\\\\\\arraybackslash\\hspace{0pt}}m{#1}}
\\newcolumntype{R}[1]{>{\\raggedleft\\let\\newline\\\\\\arraybackslash\\hspace{0pt}}m{#1}}

% Use to draw horizontal line with specific thickness
\\def\\vhrulefill#1{\\leavevmode\\leaders\\hrule\\@height#1\\hfill \\kern\\z@}

% Use to execute conditional statements by checking empty string
\\newcommand*{\\ifempty}[3]{\\ifthenelse{\\isempty{#1}}{#2}{#3}}


%-------------------------------------------------------------------------------
%                Commands for elements of CV structure
%-------------------------------------------------------------------------------
% Define a header for CV
% Usage: \\makecvheader
\\newcommand*{\\makecvheader}[1][C]{%
  \\newcommand*{\\drawphoto}{%
    \\ifthenelse{\\isundefined{\\@photo}}{}{%
      \\newlength{\\photodim}
      \\ifthenelse{\\equal{\\@photoshape}{circle}}%
        {\\setlength{\\photodim}{1.3cm}}%
        {\\setlength{\\photodim}{1.8cm}}%
      \\ifthenelse{\\equal{\\@photoedge}{edge}}%
        {\\def\\@photoborder{darkgray}}%
        {\\def\\@photoborder{none}}%
      \\begin{tikzpicture}%
        \\node[\\@photoshape, draw=\\@photoborder, line width=0.3mm, inner sep=\\photodim, fill overzoom image=\\@photo] () {};
      \\end{tikzpicture}
    }%
  }
  \\newlength{\\headertextwidth}
  \\newlength{\\headerphotowidth}
  \\ifthenelse{\\isundefined{\\@photo}}{
    \\setlength{\\headertextwidth}{\\textwidth}
    \\setlength{\\headerphotowidth}{0cm}
  }{%
    \\setlength{\\headertextwidth}{0.76\\textwidth}
    \\setlength{\\headerphotowidth}{0.24\\textwidth}
  }%
  \\begin{minipage}[c]{\\headerphotowidth}%
    \\ifthenelse{\\equal{\\@photoalign}{left}}{\\raggedright\\drawphoto}{}
  \\end{minipage}
  \\begin{minipage}[c]{\\headertextwidth}
    \\ifthenelse{\\equal{#1}{L}}{\\raggedright}{\\ifthenelse{\\equal{#1}{R}}{\\raggedleft}{\\centering}}
    \\headerfirstnamestyle{\\@firstname}\\headerlastnamestyle{{}\\acvHeaderNameDelim\\@lastname}%
    \\\\[\\acvHeaderAfterNameSkip]%
    \\ifthenelse{\\isundefined{\\@position}}{}{\\headerpositionstyle{\\@position\\\\[\\acvHeaderAfterPositionSkip]}}%
    \\ifthenelse{\\isundefined{\\@address}}{}{\\headeraddressstyle{\\@address\\\\[\\acvHeaderAfterAddressSkip]}}%
    \\headersocialstyle{%
      \\newbool{isstart}%
      \\setbool{isstart}{true}%
      \\ifthenelse{\\isundefined{\\@mobile}}%
        {}%
        {%
          \\href{tel:\\@mobile}{\\faMobile\\acvHeaderIconSep\\@mobile}%
          \\setbool{isstart}{false}%
        }%
      \\ifthenelse{\\isundefined{\\@email}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{mailto:\\@email}{\\faEnvelope\\acvHeaderIconSep\\@email}%
        }%
      \\ifthenelse{\\isundefined{\\@dateofbirth}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          % \\mbox prevents wrapping of elements%
          \\mbox{\\faBirthdayCake\\acvHeaderIconSep\\@dateofbirth}%
        }%
      \\ifthenelse{\\isundefined{\\@homepage}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{http://\\@homepage}{\\faHome\\acvHeaderIconSep\\@homepage}%
        }%
      \\ifthenelse{\\isundefined{\\@github}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://\\@github}{\\faGithubSquare\\acvHeaderIconSep\\@github}%
        }%
      \\ifthenelse{\\isundefined{\\@gitlab}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://gitlab.com/\\@gitlab}{\\faGitlab\\acvHeaderIconSep\\@gitlab}%
        }%
      \\ifthenelse{\\isundefined{\\@bitbucket}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://bitbucket.com/\\@bitbucket}{\\faBitbucket\\acvHeaderIconSep\\@bitbucket}%
        }%
      \\ifthenelse{\\isundefined{\\@stackoverflowid}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://stackoverflow.com/users/\\@stackoverflowid}{\\faStackOverflow\\acvHeaderIconSep\\@stackoverflowname}%
        }%
      \\ifthenelse{\\isundefined{\\@linkedin}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://www.\\@linkedin}{\\faLinkedin\\acvHeaderIconSep\\@linkedin}%
        }%
      \\ifthenelse{\\isundefined{\\@orcid}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://orcid.org/\\@orcid}{\\faOrcid\\acvHeaderIconSep\\@orcid}%
        }%
      \\ifthenelse{\\isundefined{\\@twitter}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://twitter.com/\\@twitter}{\\faTwitter\\acvHeaderIconSep\\@twitter}%
        }%
      \\ifthenelse{\\isundefined{\\@mastodonname}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://\\@mastodoninstance/@\\@mastodonname}{\\faMastodon\\acvHeaderIconSep\\@mastodonname}%
        }%
      \\ifthenelse{\\isundefined{\\@skype}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\faSkype\\acvHeaderIconSep\\@skype%
        }%
      \\ifthenelse{\\isundefined{\\@reddit}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://www.reddit.com/user/\\@reddit}{\\faReddit\\acvHeaderIconSep\\@reddit}%
        }%
      \\ifthenelse{\\isundefined{\\@researchgate}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://www.researchgate.net/profile/\\@researchgate}{\\faResearchgate\\acvHeaderIconSep\\@researchgate}%
        }%
      \\ifthenelse{\\isundefined{\\@xing}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://www.xing.com/profile/\\@xing}{\\faXingSquare\\acvHeaderIconSep\\@xing}
        }%
      \\ifthenelse{\\isundefined{\\@medium}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://medium.com/@\\@medium}{\\faMedium\\acvHeaderIconSep\\@medium}%
        }%
      \\ifthenelse{\\isundefined{\\@kaggle}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://kaggle.com/\\@kaggle}{\\faKaggle\\acvHeaderIconSep\\@kaggle}%
        }%
      \\ifthenelse{\\isundefined{\\@googlescholarid}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\href{https://scholar.google.com/citations?user=\\@googlescholarid}{\\faGraduationCap\\acvHeaderIconSep\\@googlescholarname}%
        }%
      \\ifthenelse{\\isundefined{\\@extrainfo}}%
        {}%
        {%
          \\ifbool{isstart}{\\setbool{isstart}{false}}{\\acvHeaderSocialSep}%
          \\@extrainfo%
        }%
    } \\\\[\\acvHeaderAfterSocialSkip]%
    \\ifthenelse{\\isundefined{\\@quote}}%
      {}%
      {\\headerquotestyle{\\@quote\\\\}\\vspace{\\acvHeaderAfterQuoteSkip}}%
  \\end{minipage}%
  \\begin{minipage}[c]{\\headerphotowidth}%
    \\ifthenelse{\\equal{\\@photoalign}{right}}{\\raggedleft\\drawphoto}{}
  \\end{minipage}
}

% Define a footer for CV
% Usage: \\makecvfooter{<left>}{<center>}{<right>}
\\newcommand*{\\makecvfooter}[3]{%
  \\fancyfoot{}
  \\fancyfoot[L]{\\footerstyle{#1}}
  \\fancyfoot[C]{\\footerstyle{#2}}
  \\fancyfoot[R]{\\footerstyle{#3}}
}

% Define a section for CV
% Usage: \\cvsection{<section-title>}
\\newcommand{\\cvsection}[1]{%
  \\vspace{\\acvSectionTopSkip}
  \\sectionstyle{#1}
  \\phantomsection
  \\color{sectiondivider}\\vhrulefill{0.9pt}
}

% Define a subsection for CV
% Usage: \\cvsubsection{<subsection-title>}
\\newcommand{\\cvsubsection}[1]{%
  \\vspace{\\acvSectionContentTopSkip}
  \\vspace{-3mm}
  \\subsectionstyle{#1}
  \\phantomsection
}

% Define a paragraph for CV
\\newenvironment{cvparagraph}{%
  \\vspace{\\acvSectionContentTopSkip}
  \\vspace{-3mm}
  \\paragraphstyle
}{%
  \\par
  \\vspace{2mm}
}

% Define an environment for cventry
\\newenvironment{cventries}{%
  \\vspace{\\acvSectionContentTopSkip}
  \\begin{center}
}{%
  \\end{center}
}
% Define an entry of cv information
% Usage: \\cventry{<position>}{<title>}{<location>}{<date>}{<description>}
\\newcommand*{\\cventry}[5]{%
  \\vspace{-2.0mm}
  \\setlength\\tabcolsep{0pt}
  \\setlength{\\extrarowheight}{0pt}
  \\begin{tabular*}{\\textwidth}{@{\\extracolsep{\\fill}} L{\\textwidth - 4.5cm} R{4.5cm}}
    \\ifempty{#2#3}
      {\\entrypositionstyle{#1} & \\entrydatestyle{#4} \\\\}
      {\\entrytitlestyle{#2} & \\entrylocationstyle{#3} \\\\
      \\entrypositionstyle{#1} & \\entrydatestyle{#4} \\\\}
    \\multicolumn{2}{L{\\textwidth}}{\\descriptionstyle{#5}}
  \\end{tabular*}%
}

% Define an environment for cvsubentry
\\newenvironment{cvsubentries}{%
  \\begin{center}
}{%
  \\end{center}
}
% Define a subentry of cv information
% Usage: \\cvsubentry{<position>}{<title>}{<date>}{<description>}
\\newcommand*{\\cvsubentry}[4]{%
  \\setlength\\tabcolsep{0pt}
  \\setlength{\\extrarowheight}{0pt}
  \\begin{tabular*}{\\textwidth}{@{\\extracolsep{\\fill}} L{\\textwidth - 4.5cm} R{4.5cm}}
    \\setlength\\leftskip{0.2cm}
    \\subentrytitlestyle{#2} & \\ifthenelse{\\equal{#1}{}}
      {\\subentrydatestyle{#3}}{}
    \\ifthenelse{\\equal{#1}{}}
      {}
      {\\subentrypositionstyle{#1} & \\subentrydatestyle{#3} \\\\}
    \\ifthenelse{\\equal{#4}{}}
      {}
      {\\multicolumn{2}{L{17.0cm}}{\\subdescriptionstyle{#4}} \\\\}
  \\end{tabular*}
}

% Define an environment for cvhonor
\\newenvironment{cvhonors}{%
  \\vspace{\\acvSectionContentTopSkip}
  \\vspace{-2mm}
  \\begin{center}
    \\setlength\\tabcolsep{0pt}
    \\setlength{\\extrarowheight}{0pt}
    \\begin{tabular*}{\\textwidth}{@{\\extracolsep{\\fill}} C{1.5cm} L{\\textwidth - 4.0cm} R{2.5cm}}
}{%
    \\end{tabular*}
  \\end{center}
}
% Define a line of cv information(honor, award or something else)
% Usage: \\cvhonor{<position>}{<title>}{<location>}{<date>}
\\newcommand*{\\cvhonor}[4]{%
  \\honordatestyle{#4} & \\honorpositionstyle{#1}, \\honortitlestyle{#2} & \\honorlocationstyle{#3} \\\\
}

% Define an environment for cvskill
\\newenvironment{cvskills}{%
  \\vspace{\\acvSectionContentTopSkip}
  \\vspace{-2.0mm}
  \\begin{center}
    \\setlength\\tabcolsep{1ex}
    \\setlength{\\extrarowheight}{0pt}
    \\begin{tabular*}{\\textwidth}{@{\\extracolsep{\\fill}} r L{\\textwidth * \\real{0.9}}}
}{%
    \\end{tabular*}
  \\end{center}
}
% Define a line of cv information(skill)
% Usage: \\cvskill{<type>}{<skillset>}
\\newcommand*{\\cvskill}[2]{%
  \\skilltypestyle{#1} & \\skillsetstyle{#2} \\\\
}

% Define an environment for cvitems(for cventry)
\\newenvironment{cvitems}{%
  \\vspace{-4.0mm}
  \\begin{justify}
  \\begin{itemize}[leftmargin=2ex, nosep, noitemsep]
    \\setlength{\\parskip}{0pt}
    \\renewcommand{\\labelitemi}{\\bullet}
}{%
  \\end{itemize}
  \\end{justify}
  \\vspace{-4.0mm}
}


%-------------------------------------------------------------------------------
%                Commands for elements of Cover Letter
%-------------------------------------------------------------------------------
% Define an environment for cvletter
\\newenvironment{cvletter}{%
  \\lettertextstyle
}{%
}

% Define a section for the cover letter
% Usage: \\lettersection{<section-title>}
\\newcommand{\\lettersection}[1]{%
  \\par\\addvspace{2.5ex}
  \\phantomsection{}
  \\lettersectionstyle{#1}
  \\color{sectiondivider}\\vhrulefill{0.9pt}
  \\par\\nobreak\\addvspace{0.4ex}
  \\lettertextstyle
}

% Define a title of the cover letter
% Usage: \\makelettertitle
\\newcommand*{\\makelettertitle}{%
  \\vspace{8.4mm}
  \\setlength\\tabcolsep{0pt}
  \\setlength{\\extrarowheight}{0pt}
  \\begin{tabular*}{\\textwidth}{@{\\extracolsep{\\fill}} L{\\textwidth - 4.5cm} R{4.5cm}}
    \\recipienttitlestyle{\\@recipientname} & \\letterdatestyle{\\@letterdate}
  \\end{tabular*}
  \\begin{singlespace}
    \\recipientaddressstyle{\\@recipientaddress} \\\\\\\\
  \\end{singlespace}
  \\ifthenelse{\\isundefined{\\@lettertitle}}
    {}
    {\\lettertitlestyle{\\@lettertitle} \\\\}
  \\lettertextstyle{\\@letteropening}
}

% Define a closing of the cover letter
% Usage: \\makeletterclosing
\\newcommand*{\\makeletterclosing}{%
  \\vspace{3.4mm}
  \\lettertextstyle{\\@letterclosing} \\\\\\\\
  \\letternamestyle{\\@firstname\\ \\@lastname}
  \\ifthenelse{\\isundefined{\\@letterenclosure}}
    {\\\\}
    {%
      \\\\\\\\\\\\
      \\letterenclosurestyle{\\@letterenclname: \\@letterenclosure} \\\\
    }
}
`;

export const FANGPATH_CLS = `
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
% Medium Length Professional CV - RESUME CLASS FILE
%
% This template has been downloaded from:
% http://www.LaTeXTemplates.com
%
% This class file defines the structure and design of the template. 
%
% Original header:
% Copyright (C) 2010 by Trey Hunner
%
% Copying and distribution of this file, with or without modification,
% are permitted in any medium without royalty provided the copyright
% notice and this notice are preserved. This file is offered as-is,
% without any warranty.
%
% Created by Trey Hunner and modified by www.LaTeXTemplates.com
%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

\\ProvidesClass{resume}[2010/07/10 v0.9 Resume class]

\\LoadClass[11pt,letterpaper]{article} % Font size and paper type

\\usepackage[parfill]{parskip} % Remove paragraph indentation
\\usepackage{array} % Required for boldface (\\bf and \\bfseries) tabular columns
\\usepackage{ifthen} % Required for ifthenelse statements

\\usepackage{hyperref}
\\hypersetup{
    colorlinks=true,
    linkcolor=blue,
    filecolor=magenta,      
    urlcolor=blue,
}

\\pagestyle{empty} % Suppress page numbers

%----------------------------------------------------------------------------------------
%	HEADINGS COMMANDS: Commands for printing name and address
%----------------------------------------------------------------------------------------

\\def \\name#1{\\def\\@name{#1}} % Defines the \\name command to set name
\\def \\@name {} % Sets \\@name to empty by default

\\def \\addressSep {$\\diamond$} % Set default address separator to a diamond

% One, two or three address lines can be specified 
\\let \\@addressone \\relax
\\let \\@addresstwo \\relax
\\let \\@addressthree \\relax

% \\address command can be used to set the first, second, and third address (last 2 optional)
\\def \\address #1{
  \\@ifundefined{@addresstwo}{
    \\def \\@addresstwo {#1}
  }{
  \\@ifundefined{@addressthree}{
  \\def \\@addressthree {#1}
  }{
     \\def \\@addressone {#1}
  }}
}

% \\printaddress is used to style an address line (given as input)
\\def \\printaddress #1{
  \\begingroup
    \\def \\\\ {\\addressSep\\ }
    \\centerline{#1}
  \\endgroup
  \\par
  \\addressskip
}

% \\printname is used to print the name as a page header
\\def \\printname {
  \\begingroup
    \\hfil{\\MakeUppercase{\\namesize\\bf \\@name}}\\hfil
    \\nameskip\\break
  \\endgroup
}

%----------------------------------------------------------------------------------------
%	PRINT THE HEADING LINES
%----------------------------------------------------------------------------------------

\\let\\ori@document=\\document
\\renewcommand{\\document}{
  \\ori@document  % Begin document
  \\printname % Print the name specified with \\name
  \\@ifundefined{@addressone}{}{ % Print the first address if specified
    \\printaddress{\\@addressone}}
  \\@ifundefined{@addresstwo}{}{ % Print the second address if specified
    \\printaddress{\\@addresstwo}}
     \\@ifundefined{@addressthree}{}{ % Print the third address if specified
    \\printaddress{\\@addressthree}}
}

%----------------------------------------------------------------------------------------
%	SECTION FORMATTING
%----------------------------------------------------------------------------------------

% Defines the rSection environment for the large sections within the CV
\\newenvironment{rSection}[1]{ % 1 input argument - section name
  \\sectionskip
  \\MakeUppercase{{\\bf #1}} % Section title
  \\sectionlineskip
  \\hrule % Horizontal line
  \\begin{list}{}{ % List for each individual item in the section
    \\setlength{\\leftmargin}{0em} % Margin within the section
  }
  \\item[]
}{
  \\end{list}
}

%----------------------------------------------------------------------------------------
%	WORK EXPERIENCE FORMATTING
%----------------------------------------------------------------------------------------

\\newenvironment{rSubsection}[4]{ % 4 input arguments - company name, year(s) employed, job title and location
 {\\bf #1} \\hfill {#2} % Bold company name and date on the right
 \\ifthenelse{\\equal{#3}{}}{}{ % If the third argument is not specified, don't print the job title and location line
  \\\\
  {\\em #3} \\hfill {\\em #4} % Italic job title and location
  }\\smallskip
  \\begin{list}{$\\cdot$}{\\leftmargin=0em} % \\cdot used for bullets, no indentation
   \\itemsep -0.5em \\vspace{-0.5em} % Compress items in list together for aesthetics
  }{
  \\end{list}
  \\vspace{0.5em} % Some space after the list of bullet points
}

% The below commands define the whitespace after certain things in the document - they can be \\smallskip, \\medskip or \\bigskip
\\def\\namesize{\\LARGE} % Size of the name at the top of the document
\\def\\addressskip{\\smallskip} % The space between the two address (or phone/email) lines
\\def\\sectionlineskip{\\medskip} % The space above the horizontal line for each section 
\\def\\nameskip{\\medskip} % The space after your name at the top
\\def\\sectionskip{\\medskip} % The space after the heading section

`;
