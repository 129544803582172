import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../shared/Login/AuthContext';
// import PromoVideo from './PromoVideo';
import { useIsMobile } from '../shared/UseIsMobile';
import Button from '@mui/material/Button';
import FeatureSection from './FeatureSection';
import midleft from '../assets/midleft.png';
import midmid from '../assets/midmid.png';
import midright from '../assets/midright.png';
import bottomleft from '../assets/bottomleft.png';
import networking from '../assets/networking.png';
import lc from '../assets/lc.png';


/**
 * - Video promo
 * - L1 - L9 Timeline
 * - A list of 9 feature cards that link to separate sections in the landing page.
 * - A separate section for each feature card that could be expanded upon.
 */

const PUBLIC_PROMO_VIDEO_ID = 'ru6tsn3vzp';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    flex: {
      display: 'flex',
      // backgroundColor: '#fff'
    },
    solid: {
      display: 'block',
      // backgroundColor: '#fff'
    },
    largeText: {
      textAlign: 'left',
      fontSize: '4em',
      margin: '40px',
      fontFamily: "'Nunito Sans', sans-serif",
    },
    semiLargeText: {
      fontSize: '2.55em',
      textAlign: 'left',
      margin: '40px',
      fontFamily: "'Nunito Sans', sans-serif",
    },
    semiLargeTextCentered: {
      fontSize: '4em',
      textAlign: 'center',
      margin: '40px',
      fontFamily: "'Nunito Sans', sans-serif",
    },
    subtitleText: {
      fontSize: '1.5em',
    },
    whoThisGuideIsFor: {
      // Put any CSS for the SVG here.
      marginTop: '40px',
    },
    smallerLargeText: {
      fontSize: '2em',
      textAlign: 'left',
      margin: '40px',
      fontFamily: "'Nunito Sans', sans-serif",
    },
  });
});

const Landing = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile({
    width: 1155
  });
  const { isLoggedIn, login, proExpirationDate } = useContext(AuthContext);
  const isPro = isLoggedIn && new Date() <= new Date(proExpirationDate);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const data = query.get('data');
    if (data) {
      console.log('logging in...');
      if (isLoggedIn) return;
      const {
        jwt_token,
        name,
        account_type,
        email,
        githubLoginUsername,
        proExpirationDate,
        image
      } = JSON.parse(data);
      login(
        jwt_token,
        null,
        email,
        githubLoginUsername,
        account_type, 
        name,
        proExpirationDate,
        image,
      );
      console.log("Github email: ", email);
      window.location.href = '/';
      if (email === "N/A") {
        alert("Your Github account doesn't have an email! If you would like to receive future updates, please add an email by clicking on your profile.")
      }
    }
  }, []);
  return (
    <React.Fragment>
      <div className={isMobile ? classes.solid : classes.flex}>
        <div>
          <h1 className={!isMobile ? classes.largeText : classes.semiLargeText}>
            Real Tech Prep
          </h1>
          {/* <h1 className={!isMobile ? classes.semiLargeText : ''}>
            Don't be the person who grinded 600 leetcode problems but no interview... 😔
          </h1> */}
          <h1 className={!isMobile ? classes.semiLargeText : ''}>
            Build your resume with us. We will help you get noticed. 
          </h1>
          <h1 className={!isMobile ? classes.smallerLargeText : ''}><u>Don't be the person who grinded 600 leetcode problems but no interview... 😔</u></h1>
          {/* <h1 className={!isMobile ? classes.semiLargeText : ''}>
            We help you land interviews by providing the ultimate platform to build and maintain your tech resume, working with companies who 
            might be interested in your profile, providing custom online assessments and take-home projects to help you land more interviews.
          </h1> */}
          <Button
            variant="contained"
            size="large"
            style={{ margin: isMobile ? '15px' : '40px' }}
            onClick={() => history.push(isPro ? 'next-steps' : '/enroll')}
          >
            <span className={classes.subtitleText}>
              {isPro ? <b>Next Steps</b> : <b>Enroll</b>}
            </span>
          </Button>
        </div>
        {/* <PromoVideo vidId={PUBLIC_PROMO_VIDEO_ID} /> */}
      </div>
      <div>
        {/* <WhoThisGuideIsFor className={classes.whoThisGuideIsFor} /> */}
        <h1
          className={
            !isMobile ? classes.semiLargeTextCentered : classes.semiLargeText
          }
        >
          Here's how we can help:
        </h1>
        <div
          style={{
            width: !isMobile ? '490px' : '270px',
            textAlign: 'center',
            margin: 'auto',
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '20px',
          }}
        >
          <h2
            className={!isMobile ? classes.semiLargeText : classes.subtitleText}
          >
            1. Build THE resume. 
          </h2>

        </div>
        <FeatureSection
          rowData={[
          {
            title: 'The Ultimate Tech Resume Builder',
            shortDescription:
              'Build latex resumes that passes ATS systems. Unlock all premium templates that was used to land interviews with Microsoft, Google.',
            description: `Companies use an automated system - ATS (applicant tracking system) to automatically parse and filter out resumes.
            This is why the template you use and which keywords you include in your resume will determine whether or not a person will even look at your resume.`,
            img: midmid,
            buttonText: 'Try it now',
            buttonCallback: () => (history.push("/resume")),
          }
            // {
            //   title: 'Fully featured resume builder',
            //   description: `
            //     Build 2 fullstack projects end to end with core technologies used in modern tech stacks. Emphasis on 
            //     requirements specification, object oriented design, agile methodologies, and design patterns. 
            //     Technologies: Typescript + React, Node.js + Express, Docker + Kubernetes, 
            //     postgreSQL + MongoDB.
            //   `,
            //   shortDescription:
            //     'Learn to build full stack applications using Agile methodologies, object oriented design, and popular design patterns.',
            //   img: topleft,
            //   buttonCallback: () => (history.push("/content/get-experience?p=fullstack-apps")),
            // },
            // {
            //   title: '2 Algorithmic Coding Projects',
            //   description: `
            //   In real software engineering contexts, it is common having to mix theory with practice to get the job done. 
            //   Not everything can be a copy+paste from stack overflow, sometimes the problem just hasn't been solved before.
            //   These algorithmic projects challenge your creative problem solving skills and tests your implementation skills.
            //   `,
            //   img: topmid,
            //   shortDescription:
            //     'Combine theory and practice: implement advanced computer science / statistics / machine learning algorithms using Python / numpy, and C++.',
            //   buttonCallback: () => (history.push("/content/get-experience?p=algo-apps")),
            // },
            // {
            //   title: '1 Low Level Systems Project',
            //   shortDescription: `
            //   Learn what most coding bootcamps won't teach,
            //    but will 
            //   land you better offers in the long run. An understanding 
            //   of low level behavior is key.
            //   `,
            //   description: `Ultimately, knowing the low level implementations will unlock far more than having to rely on abstractions.
            //     You will first be introduced to the low level concepts, then build a few simple programs in Assembly, and then 
            //     implement a final project where you will optimize C code to maximize performance.
            //     `,
            //   img: topright,
            //   buttonCallback: () => (history.push("/content/get-experience?p=lowlevel-app")),
            // },
          ]}
        />
        <div
          style={{
            width: !isMobile ? '490px' : '270px',
            textAlign: 'center',
            margin: 'auto',
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '20px',
          }}
        >
          <h2
            className={!isMobile ? classes.semiLargeText : classes.subtitleText}
          >
            2. Get The Interview.
          </h2>
        </div>
        <FeatureSection
          rowData={[
            {
              title: 'On-Demand Support',
              description: `
              Email support or through discord. We are here to help you remove fluff from your resume and focus on metrics companies care about.
              `,
              img: midright,
              shortDescription: `
              Need help formulating your bullet points? Get personalized advice from industry professionals to help you land more interviews.`,
              // buttonCallback: () => (history.push("/content/get-interview?p=cracking-oa")),
            },
            {
              title: 'We Contact Companies FOR You.',
              shortDescription:
                'The resume YOU build on our platform will be visible to recruiters hiring software engineers. Simply sign up for a FREE account using our FREE templates. Increase visibility with pro features.',
              description: `By using our platform, we abstract away much of the hassle of getting visibility for your resume. Your saved resume will be visible to the right people.`,
              img: networking,
              // buttonCallback: () => (history.push("/resume")),
            },
            {
              title: 'Do our online assessments. We send your scores to relevant companies.',
              shortDescription: `
              Complete customized online assessments crafted by reputable tech companies, and send your scores along with your resume with RealTechPrep!
     
              `,
              description:
                'We work with tech companies looking for great team mates. Together, we put together a list of online assessments you can do, where we will send your scores to relevant companies.',
              img: midleft,
              // buttonCallback: () => (history.push("/content/get-interview?p=resume-screening")),
            },
          ]}
        />
        <div
          style={{
            width: !isMobile ? '490px' : '270px',
            textAlign: 'center',
            margin: 'auto',
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '20px',
          }}
        >
          <h2
            className={!isMobile ? classes.semiLargeText : classes.subtitleText}
          >
            3. Get The Offer.
          </h2>
        </div>
        <FeatureSection
          rowData={[
            {
              title: 'Verified Leetcode Premium discount codes',
              description: `Once you secured the interview, we partered with Leetcode to get you 10-40% off premium.
              `,
              shortDescription: `Get 10% - 40% off leetcode premium with VERIFIED codes when you sign up for pro features.`,
              img: lc,
              // buttonCallback: () => (history.push("/content/get-offer?p=fundamental-dsa")),
            },
            // {
            //   title: 'Algorithm Patterns Deep Dive',
            //   description: `Leetcode easies are applications of data structures and simple implementation, mediums are usually 1 pattern + data structure. Leetcode hards are 
            //     usually a combination of 2+ patterns + data structures + math tricks.`,
            //   shortDescription: `Learn the algorithmic patterns that root most technical interview problems through detailed video conceptual walkthroughs.`,
            //   img: bottommid,
            //   buttonCallback: () => (history.push("/content/get-offer?p=coding-patterns")),
            // },
            // {
            //   title: 'System Design Overview',
            //   description: `Get exposure to TCP/UDP, OCI stack, API design, NoSQL vs SQL, caching, load balancing, CAP theorem, ACID compliance, and more!`,
            //   shortDescription: `Learn the foundations of system design through detailed video walkthroughs of case studies of real life systems.`,
            //   img: bottomright,
            //   buttonCallback: () => (history.push("/content/get-offer?p=system-design")),
            // },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default Landing;
