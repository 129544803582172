export const LIMIT_SIGNUPS_FLAG = true;

// assume everyone knows this
export const GOOGLE_CLIENT_ID =
  '403698884941-lrnthbu29k6cg9s31m4vqqv789g2r6i8.apps.googleusercontent.com';
// github client id = 23f3d1def5290db74e9a <-- assume everyone knows this
export const GITHUB_LOGIN_URL = '/oauth2/github/login';

export const LOCAL_STORAGE_KEY_OFFLINE = 'local_resume_offline';

export const LOCAL_STORAGE_KEY_ONLINE = 'local_resume';

export const EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION = '1111-11-11T00:00:00.000+00:00';
