export const batchFetch = async (urls: string[], progress?: any) => {
  const responsePromises = [];
  let loadProgress = 0;
  for (const url of urls) {
    responsePromises.push(
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          loadProgress += 100 / urls.length;
          loadProgress = Math.floor(loadProgress);
          console.log(loadProgress);
          if (progress) progress(loadProgress);
          return jsonData;
        }),
    );
  }
  const jsonResponses = await Promise.all(responsePromises);

  console.log('All URLs finished fetching response');

  return jsonResponses;
};
