import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import github_logo from './github.svg';
import { useContext } from 'react';
import { AuthContext } from '../Login/AuthContext';
import { GOOGLE_CLIENT_ID } from '../../config';
import { GITHUB_LOGIN_URL } from '../../config';


const LoginPopup = (props: any) => {
  const { open, handleClose } = props;
  const auth = useContext(AuthContext);

  const responseSuccessGoogle = async (data: any) => {
    try {
      let response = await fetch(
        '/oauth2/google/callback?accessToken=' + data['credential'],
      );
      let json = await response.json();
      if (!response.ok) {
        throw new Error(JSON.stringify(json));
      }
      auth.login(
        json['rtp_jwt_token'],
        null,
        json['email'],
        json['githubLoginUsername'],
        json['account_type'],
        json['name'],
        json['proExpirationDate'],
        json['image'],
      );
      window.location.href = "/";
    } catch (err) {
      console.log('error! ', err);
      alert('Error validating google credentials: ' + err);
    } 
  };

  const responseFailureGoogle = () => {
    console.log('Login failure for google!');
    alert('Login failure.');
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Sign in to Real Tech Prep
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Log in to save your progress and your resume template. By signing
            in, you agree to RealTechPrep's terms of service and privacy policy.
          </DialogContentText>
          <div>
            <div style={{ maxWidth: '200px', margin: '15px' }}>
              <GoogleLogin
                onSuccess={responseSuccessGoogle}
                onError={responseFailureGoogle}
              />
            </div>
            <div style={{ maxWidth: '250px', margin: '15px' }}>
              <Button
                onClick={() => {
                  window.location.href = GITHUB_LOGIN_URL;
                }}
                variant="outlined"
              >
                <div style={{ display: 'flex' }}>
                  <img
                    src={github_logo}
                    height={'25px'}
                    width={'25px'}
                    style={{ marginRight: '5px' }}
                  ></img>
                  Use Github
                </div>
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </GoogleOAuthProvider>
  );
};

export default LoginPopup;
