import { useEffect, useState } from 'react';
import { PDF_ENGINE } from '../PdfLatexEngine';
import { render } from '../RenderTemplate';
import { fetchTemplate } from '../Util';
import Previewer from '../editor/Previewer';
import { batchFetch } from '../../shared/BatchFetch';
import { combine } from '../Util';

const FreeResume = () => {
  const [pdfEngine, setPdfEngine] = useState<any>(null);
  const [pdfObject, setPdfObject] = useState('');
  const [freeTemplate, setFreeTemplate] = useState(null);
  const [progress, setProgress] = useState(0); 
  
  useEffect(() => {
    // Initialize the PDF engine. Ready to be re-used.
    const initializePdfLatexEngine = async () => {
      try {
        console.log('Initializing PDF engine.');
        let globalEn = new PDF_ENGINE(
          '/pdf-engine/jake/jakes_resume_pdflatex.js',
        );
        const pieces = await batchFetch([
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_0.json",
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_1.json",
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_2.json",
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_3.json",
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_4.json",
          "https://pdf-latex-xelatex-blob.s3.us-west-1.amazonaws.com/jake_slice_5.json"
        ], setProgress);
        const data = combine(pieces, pieces.length);
        // Setup
        await globalEn.loadEngine(data);
        setPdfEngine(globalEn);
        console.log('Main thread: Successfully loaded PDF engine.');
      } catch (err) {
        alert('Error loading PDF engine. Error: ' + err);
      }
    };

    if (pdfEngine === null && pdfObject.length === 0 && freeTemplate !== null) {
      initializePdfLatexEngine();
    }
    if (freeTemplate === null) {
      fetchTemplate('/api/content/resume/free', '', setFreeTemplate);
    }
  }, [freeTemplate]);

  const compile = async (resumeObject: any, callbackFunction?: any) => {
    if (pdfEngine === null) {
      console.log('Engine not ready yet!');
      return;
    }
    let compiledResult = ""
    try {
      // Compile
      compiledResult = render(resumeObject, freeTemplate);
      // console.log(compiledResult);
      pdfEngine.writeMemFSFile('main.tex', compiledResult);
      pdfEngine.setEngineMainFile('main.tex');
      let result = await pdfEngine.compileLaTeX();
      if (result.status === 0) {
        const pdfblob = new Blob([result.pdf], { type: 'application/pdf' });
        const objectURL = URL.createObjectURL(pdfblob);
        setPdfObject(objectURL);
      } else {
        throw new Error(JSON.stringify(result));
      }
    } catch (err) {
      alert('Error compiling PDF. Error: ' + err);
    }

    if (callbackFunction) callbackFunction(compiledResult);
    return compiledResult;
  };

  return (
    <Previewer
      compile={compile}
      pdfObject={pdfObject}
      engineIsReady={pdfEngine !== null}
      parentTemplate={'jake'}
      progress={progress}
    />
  );
};

export default FreeResume;
