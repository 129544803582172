import { useContext, useEffect, useState } from 'react';
import { PDF_ENGINE } from '../PdfLatexEngine';
import { render } from '../RenderTemplate';
import { AuthContext } from '../../shared/Login/AuthContext';
import { fetchTemplate } from '../Util';
import Previewer from '../editor/Previewer';
import { FANGPATH_CLS } from '../Util';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../../config';

const DefaultPremiumResume = () => {
  const [pdfEngine, setPdfEngine] = useState<any>(null);
  const [pdfObject, setPdfObject] = useState('');
  const [defaultPremiumTemplate, setDefaultPremiumTemplate] = useState(null);
  const { isLoggedIn, token, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);

  useEffect(() => {
    // Initialize the PDF engine. Ready to be re-used.
    const initializePdfLatexEngine = async () => {
      try {
        console.log('Initializing PDF engine.');
        let globalEn = new PDF_ENGINE(
          '/pdf-engine/fang/fang_path_resume_pdflatex.js',
        );
        console.log(globalEn);
        // Setup
        await globalEn.loadEngine();
        globalEn.writeMemFSFile('resume.cls', FANGPATH_CLS);
        setPdfEngine(globalEn);
        console.log('Successfully loaded PDF engine.');
      } catch (err) {
        alert('Error loading PDF. Error: ' + err);
      }
    };

    if (
      pdfEngine === null &&
      pdfObject.length === 0 &&
      defaultPremiumTemplate !== null
    ) {
      initializePdfLatexEngine();
    }
    if (defaultPremiumTemplate === null && canAccess) {
      fetchTemplate(
        '/api/content/resume/premium/default',
        token,
        setDefaultPremiumTemplate,
      );
    }
  }, [defaultPremiumTemplate, canAccess]);

  const compile = async (resumeObject: any, callbackFunction: any) => {
    if (pdfEngine === null) {
      return;
    }
    let compiledResult = '';
    try {
      // Compile
      compiledResult = render(resumeObject, defaultPremiumTemplate);
      // console.log(compiledResult);
      pdfEngine.writeMemFSFile('main.tex', compiledResult);
      pdfEngine.setEngineMainFile('main.tex');
      let result = await pdfEngine.compileLaTeX();
      if (result.status === 0) {
        const pdfblob = new Blob([result.pdf], { type: 'application/pdf' });
        const objectURL = URL.createObjectURL(pdfblob);
        setPdfObject(objectURL);
      } else {
        throw new Error(JSON.stringify(result));
      }
    } catch (err) {
      alert('Error compiling PDF. Error: ' + err);
    }
    if (callbackFunction) callbackFunction(compiledResult);
  };

  return (
    <Previewer
      compile={compile}
      pdfObject={pdfObject}
      engineIsReady={pdfEngine !== null}
      parentTemplate={'fang'}
    />
  );
};

export default DefaultPremiumResume;
