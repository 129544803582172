import { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavLink, useHistory } from 'react-router-dom';
import './NavigationBar.scss';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import MobileNav from './MobileNav';
import { tabs } from './Tabs';
import LoginPopup from '../Login/LoginPopup';
import { AuthContext } from '../Login/AuthContext';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navGroup_equal: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
    },
    navGroup_medium: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
      marginLeft: '5%',
      marginRight: '7%',
    },
    navGroup_large: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
      marginLeft: '10%',
      marginRight: '15%',
    },
    navGroup_x_large: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
      marginLeft: '15%',
      marginRight: '20%',
    },
    navGroup_x_x_large: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
      marginLeft: '20%',
      marginRight: '25%',
    },
    navGroup_x_x_x_large: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: 500,
      marginLeft: '25%',
      marginRight: '32%',
    },
    root: {
      flexGrow: 1,
    },
    button: {
      marginRight: theme.spacing(5),
      marginLeft: theme.spacing(5),
    },
    menu: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 80,
      margin: 5,
    },
  }),
);

const NavigationBar = (props: any) => {
  const classes = useStyles();
  const [focus, setFocus] = useState([false, false, false]);
  const location = useLocation().pathname;
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const { isLoggedIn, logout, proExpirationDate, image } =
    useContext(AuthContext);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const history = useHistory();

  console.log('IsLoggedIn-navbar', isLoggedIn);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    let rootLocation = location.split('/');
    let pathMatch = location;
    if (rootLocation.length > 1) pathMatch = rootLocation[1];
    switch (pathMatch) {
      case 'content':
        document.title = 'Content | RealTechPrep';
        setFocus([true, false, false]);
        break;
      case 'resume':
        document.title = 'Resume | RealTechPrep';
        setFocus([false, true, false]);
        break;
      case 'enroll':
        document.title = 'Enroll | RealTechPrep';
        setFocus([false, false, true]);
        break;
      case 'next-steps':
        document.title = 'Next Steps | RealTechPrep';
        setFocus([false, false, true]);
        break;
      default:
        document.title =
          'RealTechPrep | Realistic guide to get a 200K job in tech';
        setFocus([false, false, false]);
    }
  }, [location]);

  const desktopView = (
    <Toolbar>
      <NavLink to={'/'}>
        <img
          src={logo}
          alt="logo"
          className={classes.logo + ' hvr-grow-rotate'}
          id="logo"
        />
      </NavLink>

      <div
        className={
          innerWidth <= 1015
            ? classes.navGroup_equal
            : innerWidth <= 1150
            ? classes.navGroup_medium
            : innerWidth <= 1400
            ? classes.navGroup_large
            : innerWidth <= 1735
            ? classes.navGroup_x_large
            : innerWidth <= 2135
            ? classes.navGroup_x_x_large
            : classes.navGroup_x_x_x_large
        }
      >
        {tabs.map((tab, index) => {
          return (
            <Typography
              variant="subtitle1"
              className={classes.title}
              key={tab.url}
            >
              <NavLink
                to={tab.url}
                className={
                  focus[index] ? 'white highlighted' : 'white not-highlighted'
                }
              >
                {tab.text}
              </NavLink>
            </Typography>
          );
        })}
        {isLoggedIn && new Date() < new Date(proExpirationDate) ? (
          <Typography
            variant="subtitle1"
            className={classes.title}
            key={'next-steps'}
          >
            <NavLink
              to={'/next-steps'}
              className={
                focus[focus.length - 1]
                  ? 'white highlighted'
                  : 'white not-highlighted'
              }
            >
              <b>
                <i>NEXT STEPS</i>
              </b>
            </NavLink>
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            className={classes.title}
            key={'enroll'}
          >
            <NavLink
              to={'/enroll'}
              className={
                focus[focus.length - 1]
                  ? 'white highlighted'
                  : 'white not-highlighted'
              }
            >
              <b>
                <i>ENROLL</i>
              </b>
            </NavLink>
          </Typography>
        )}
      </div>

      {!isLoggedIn ? (
        <Button
          color="default"
          variant="contained"
          onClick={() => {
            setLoginPopupOpen(true);
          }}
        >
          <b>Log in</b>
        </Button>
      ) : (
        <a href="#" onClick={() => {history.push("/profile")}}>
          <Avatar alt="Profile" src={image} style={{ border: '1px solid white' }}/>
        </a>
      )}
    </Toolbar>
  );

  const mobileView = (
    <Toolbar>
      <div className={classes.root}>
        <MobileNav
          menuClassName={classes.menu}
          menuStyle={{ color: '#fff' }}
          title={classes.title}
          button={classes.button}
        />
      </div>
      {!isLoggedIn ? (
        <Button
          color="default"
          variant="contained"
          onClick={() => {
            setLoginPopupOpen(true);
          }}
        >
          <b>Log in</b>
        </Button>
      ) : (
        <a href="#" onClick={() => {history.push("/profile")}}>
          <Avatar alt="Profile" src={image}  style={{ border: '1px solid white' }} />
        </a>
      )}
    </Toolbar>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="inherit"
        style={{ backgroundColor: '#000' }}
      >
        <LoginPopup
          open={loginPopupOpen}
          handleClose={() => {
            setLoginPopupOpen(false);
          }}
        />
        {innerWidth >= 1000 ? desktopView : mobileView}
      </AppBar>
    </div>
  );
};

export default NavigationBar;
