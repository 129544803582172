import { Card, CardContent } from '@material-ui/core';

const Terms = () => {
  return (
    <Card style={{ margin: '30px', fontFamily: '"Nunito Sans", sans-serif' }}>
      <CardContent>
        <h1>Terms of Service</h1>
        <p>
          By accessing this website, you are agreeing to be bound by these
          website Terms and Conditions of Use, all applicable laws, and
          regulations, and agree that you are responsible for compliance with
          any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The
          materials contained in this website are protected by applicable
          copyright and trademark law. RealTechPrep is a wholly-owned subsidiary
          of JackHeTech LLC.
        </p>
        <h2>Intellectual Property Rights</h2>
        <p>
          You are granted a non-exclusive, non-transferable, revocable license
          to access and use the Site strictly in accordance with these terms of
          use. As a condition of your use of the Site, you warrant to
          RealTechPrep that you will not use the Site for any purpose that is
          unlawful or prohibited by these Terms. You may not use the Site in any
          manner which could damage, disable, overburden, or impair the Site or
          interfere with any other party's use and enjoyment of the Site. You
          may not obtain or attempt to obtain any materials or information
          through any means not intentionally made available or provided for
          through the Site. All content included as part of the Service, such as
          text, graphics, logos, images, as well as the compilation thereof, and
          any software used on the Site, is the property of RealTechPrep or its
          suppliers and protected by copyright and other laws that protect
          intellectual property and proprietary rights. You agree to observe and
          abide by all copyright and other proprietary notices, legends or other
          restrictions contained in any such content and will not make any
          changes thereto. You will not modify, publish, transmit, reverse
          engineer, participate in the transfer or sale, create derivative
          works, or in any way exploit any of the content, in whole or in part,
          found on the Site. <b>RealTechPrep content is not for resale.</b> Your
          use of the Site does not entitle you to make any unauthorized use of
          any protected content, and in particular you will not delete or alter
          any proprietary rights or attribution notices in any content. You will
          use protected content solely for your personal use, and will make no
          other use of the content without the express written permission of
          RealTechPrep and the copyright owner. You will not record, upload, or
          stream videos of yourself using any part of the content and/or of the
          platform that requires purchasing in order to be accessed. You agree
          that you do not acquire any ownership rights in any protected content.
          We do not grant you any licenses, express or implied, to the
          intellectual property of RealTechPrep or our licensors except as
          expressly authorized by these Terms.
        </p>
        <h2>Content Copyright Policy</h2>
        <p>
          The site design, logo, video content subject to copyright © 2023 -
          present, RealTechPrep.com
        </p>
        <h2>Memberships</h2>
        <p>
          Fees for RealTechPrep lifetime access is charged only once, and
          membership begins immediately upon payment. If the course is full,
          there will be a priority waitlist and a regular waitlist opening up.
          Sign ups for the regular waitlist is free - all you need to do is sign
          up. Sign ups for the priority waitlist allows for a discount on the
          full membership once spots open again, a small fee is charged to be on
          the priority waitlist (overall cost less than the full membership).
          Refunds: All sales are final. No refunds will be issued. If you
          believe there was a mistake, please reach out to jack@jackhetech.io.
        </p>
        <h2>Liability Disclaimer</h2>
        <p>
          In no event shall RealTechPrep liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to
          business interruption) arising out of the use or inability to use the
          materials on the RealTechPrep Internet site, even if RealTechPrep or a
          RealTechPrep authorized representative has been notified orally or in
          writing of the possibility of such damage. Because some jurisdictions
          do not allow limitations on implied warranties, or limitations of
          liability for consequential or incidental damages, these limitations
          may not apply to you. The materials on the RealTechPrep website are
          provided “as is”. RealTechPrep makes no warranties, expressed or
          implied, and hereby disclaims and negates all other warranties,
          including without limitation, implied warranties or conditions of
          merchantability, fitness for a particular purpose, or non-infringement
          of intellectual property or other violation of rights. Further,
          RealTechPrep does not warrant or make any representations concerning
          the accuracy, likely results, or reliability of the use of the
          materials on its Internet website or otherwise relating to such
          materials or on any sites linked to this site.
        </p>
        <h2>Governing Law</h2>
        <p>
          Any claim relating to the RealTechPrep website shall be governed by
          the laws of the province of British Columbia, Canada.
        </p>
      </CardContent>
    </Card>
  );
};

export default Terms;
