import {
  makeStyles,
  Theme,
  createStyles,
  Button as NavButton,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../shared/Login/AuthContext';
import { useIsMobile } from '../shared/UseIsMobile';
import Button from '@mui/material/Button';

/**
 * Takes in a list of video ids, and renders a videos view where the user can select which
 * video from the side-list they want to watch right now.
 */

interface Video {
  title: string;
  video_id: string;
  url_path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flexGrow: 1,
      display: 'flex',
      marginRight: '30px',
      marginLeft: '30px',
    },
    column: {
      margin: '10px',
    },
    lockScreen: {
      backgroundColor: 'black',
      width: '75vw', // This remains fixed.
      height: '42vw', // This remains fixed.
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    iframeWrapper: {
      width: '75vw', // This remains fixed.
      height: '42vw', // This remains fixed.
    },
    iframeWrapperMobile: {
      width: '100vw', // This remains fixed.
      height: '56vw', // This remains fixed.
    },
    sideList: {
      overflow: 'scroll',
      height: '42vw', // This can change
      border: '1px solid black',
      width: '20vw'
    },
    sideListMobile: {
      overflow: 'scroll',
      height: '42vh', // This can change
      width: '100vw',
      border: '1px solid black',
    },
    contentView: {
      border: '1px solid black',
      width: '76vw',
      height: '48vw', // TODO: increase this to however tall you need to accomodate any additional content.
      overflow: 'scroll',
    },
    contentMobileView: {
      border: '1px solid black',
      width: '100vw',
      height: '40vh', // TODO: increase this to however tall you need to accomodate any additional content.
      overflow: 'scroll',
    },
    button: {
      margin: '30px',
    },
    cell: {
      margin: '10px',
    },
    selectedCell: {
      backgroundColor: 'lightblue',
      margin: '10px',
    },
  }),
);

const VideosView = (props: { videosList: Array<Video> }) => {
  const { isLoggedIn, logout, token, proExpirationDate } =
    useContext(AuthContext);
  const isMember = isLoggedIn && new Date() < new Date(proExpirationDate);
  const videoList = props.videosList;
  const [currVideo, setCurrVideo] = useState(0);
  const [currVideoSecret, setCurrVideoSecret] = useState('');
  const isMobile = useIsMobile({
    width: 885,
  });
  const classes = useStyles();
  const history = useHistory();
  const canAccess = isMember || videoList[currVideo].url_path === 'free-content';

  useEffect(() => {
    const getContent = async () => {
      try {
        const resourceURL = '/api/content/' + videoList[currVideo].url_path +'/' + videoList[currVideo].video_id;
        console.log('resourceURL: ' + resourceURL);
        const response = await fetch(
          resourceURL,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const content = await response.json();
        if (!response.ok) {
          throw new Error(content['message']);
        }
        setCurrVideoSecret(content['video_url']);
      } catch (err) {
        if (
          String(err).includes('Expired or invalid access token') &&
          !String(err).includes('Non-pro member')
        ) {
          alert('Your session has expired, please log in again.');
          console.log(err);
          logout();
          window.location.reload();
        } else {
          alert(err);
        }
      }
    };
    // If is member, then fetch the currently selected video. Otherwise, show locked.
    if (canAccess) {
      setCurrVideoSecret('');
      getContent();
    }
  }, [currVideo, canAccess]);
  const sideview = (
    <div className={isMobile ? classes.sideListMobile : classes.sideList}>
      {videoList.map((vid, idx) => (
        <a href="javascript: void(0)" className="boring-link" key={idx}>
          <div
            onClick={() => {
              setCurrVideo(idx);
            }}
            key={idx}
            className={currVideo === idx ? classes.selectedCell : classes.cell}
          >
            <p style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
              {String(idx) + '. ' + vid.title}
            </p>
          </div>
        </a>
      ))}
    </div>
  );

  const contentview = (
    <div className={isMobile ? classes.contentMobileView : classes.contentView}>
      {/* Video title */}
      <h1 style={{ margin: '10px', fontFamily: "'Nunito Sans', sans-serif" }}>
        {String(currVideo) + '. ' + videoList[currVideo].title}
      </h1>
      {/* Video view */}
      {canAccess && currVideoSecret === '' && <div className="spinner"></div>}
      {canAccess && currVideoSecret && (
        <div className={isMobile ? classes.iframeWrapperMobile : classes.iframeWrapper}>
          <iframe
            src={currVideoSecret}
            width="100%"
            height="100%"
            frameBorder={0}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
      {!canAccess && (
        <div className={classes.lockScreen}>
          <Button
            variant="contained"
            size="large"
            style={{ margin: 'auto' }}
            onClick={() => history.push('/enroll')}
          >
            <b>Get Full Access</b>
          </Button>
        </div>
      )}
      {/* Put any additional content (like zip file downloads, practice problems) here. */}
    </div>
  );

  return (
    <div>
      <NavButton
        color="default"
        variant="contained"
        onClick={() => history.goBack()}
        className={classes.button}
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
          </g>
        </svg>
      </NavButton>
      <div className={isMobile ? classes.column : classes.row}>
        {isMobile ? (
          <React.Fragment>
            {contentview}
            {sideview}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {sideview} {contentview}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default VideosView;
