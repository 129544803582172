import { useContext, useEffect, useState } from 'react';
import { XETEX_ENGINE } from '../XeTeXEngine';
import { DVI_ENGINE } from '../DvipdfmxEngine';
import { AuthContext } from '../../shared/Login/AuthContext';
import { fetchTemplate } from '../Util';
import { render } from '../RenderTemplate';
import Previewer from '../editor/Previewer';
import { RUSSELL_CLS } from '../Util';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../../config';

const RussellResume = () => {
  const [xetexEngine, setXetexEngine] = useState<any>(null);
  const [dvipdfmxEngine, setDvipdfmxEngine] = useState<any>(null);
  const [pdfObject, setPdfObject] = useState('');
  const { isLoggedIn, token, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);
  const [russellTemplate, setRussellTemplate] = useState(null);

  useEffect(() => {
    const initializePdfLatexEngine = async () => {
      try {
        console.log('Initializing Xetex PDF engine.');
        let globalEn = new XETEX_ENGINE(
          '/pdf-engine/russ/russell_resume_xelatex.js',
        );
        let dvipdfmxEn = new DVI_ENGINE(
          '/pdf-engine/russ/russell_resume_dvipdfm.js',
        );
        // Setup
        await globalEn.loadEngine();
        await dvipdfmxEn.loadEngine();
        globalEn.writeMemFSFile('russell.cls', RUSSELL_CLS);
        setXetexEngine(globalEn);
        setDvipdfmxEngine(dvipdfmxEn);
        console.log('Initialized Xetex PDF engine and DVI engine.');
      } catch (err) {
        alert('Error loading xetex engine: ' + err);
      }
    };
    if (
      dvipdfmxEngine === null &&
      xetexEngine === null &&
      pdfObject.length === 0 &&
      russellTemplate !== null
    ) {
      initializePdfLatexEngine();
    }
    if (russellTemplate === null && canAccess) {
      fetchTemplate(
        '/api/content/resume/premium/russ',
        token,
        setRussellTemplate,
      );
    }
  }, [russellTemplate, canAccess]);

  const compile = async (resumeObject: any, callbackFunction: any) => {
    if (xetexEngine === null || dvipdfmxEngine === null) {
      console.log('Engine not ready yet!');
      return;
    }
    let compiledResult = '';
    try {
      // Compile
      compiledResult = render(resumeObject, russellTemplate);
      // console.log(compiledResult);
      xetexEngine.writeMemFSFile('main.tex', compiledResult);
      xetexEngine.setEngineMainFile('main.tex');
      let result = await xetexEngine.compileLaTeX();
      if (result.status === 0) {
        dvipdfmxEngine.writeMemFSFile('main.xdv', result.pdf);
        dvipdfmxEngine.setEngineMainFile('main.xdv');
        let final_result = await dvipdfmxEngine.compilePDF();
        const pdfblob = new Blob([final_result.pdf], {
          type: 'application/pdf',
        });
        const objectURL = URL.createObjectURL(pdfblob);
        setPdfObject(objectURL);
      } else {
        throw new Error(JSON.stringify(result));
      }
    } catch (err) {
      alert('Error compiling PDF. Error: ' + err);
    }
    if (callbackFunction) callbackFunction(compiledResult);
  };

  return (
    <Previewer
      compile={compile}
      pdfObject={pdfObject}
      engineIsReady={xetexEngine !== null && dvipdfmxEngine !== null}
      parentTemplate={'russ'}
    />
  );
};

export default RussellResume;
