import { useHistory } from 'react-router-dom';
import { AuthContext } from '../shared/Login/AuthContext';
import HoverCard from '../shared/HoverCard/HoverCard';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useIsMobile } from '../shared/UseIsMobile';
import ge from '../assets/ge.png';
import gi from '../assets/gi.png';
import go from '../assets/go.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flexGrow: 1,
      display: 'flex',
      margin: '30px',
    },
    column: {
      margin: '10px',
    },
  }),
);

const Content = () => {
  const classes = useStyles();
  const screenTooNarrow = useIsMobile({
    width: 1618,
  });
  const isMobile = useIsMobile({
    width: 768,
  });
  const history = useHistory();

  return (
    <div className={screenTooNarrow ? classes.column : classes.row}>
      <HoverCard
        projectTitle="Get The Experience"
        projectDescription={`The goal of this module is to help you develop the most important skill 
              in software engineering: the ability to break down problems into smaller, more manageable pieces. 
              As an aside, if you're L1 - L4, this module will give you the necessary full stack skills to 
              develop any project you want, any idea you want, to get those entry level roles in local tech companies.`}
        shortDescription="Software engineering in a nutshell; Learn the top 10% most useful part of my computer science degree."
        buttonText="Start Module"
        buttonCallback={() => {
          history.push('/content/get-experience');
        }}
        img={ge}
        isMobileView={isMobile}
      />

      <HoverCard
        projectTitle="Get The Interview"
        projectDescription={`The goal of this module is to give you a solid intuition about how to structure your resume and
         communicate with recruiters to maximize your chances of getting the interview.`}
        shortDescription="Learn what tech recruiters look for in a resume, and how you can build the best resume given your prior experience."
        buttonText="Start Module"
        buttonCallback={() => {
          history.push('/content/get-interview');
        }}
        img={gi}
        isMobileView={isMobile}
      />

      <HoverCard
        projectTitle="Get The Offer"
        projectDescription={`The goal of this module is to set you up for success in the interview process, once you are at the 
        stage where you can consistently get interviews and you have the software engineering skills to succeed in virutally any role.`}
        shortDescription="Learn the core patterns that root most technical interview problems. Supercharge your interview grind with detailed practice problem walkthroughs."
        buttonText="Start Module"
        buttonCallback={() => {
          history.push('/content/get-offer');
        }}
        img={go}
        isMobileView={isMobile}
      />
    </div>
  );
};

export default Content;
