import { useIsMobile } from '../../shared/UseIsMobile';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../shared/Login/AuthContext';
import { DEFAULT_RESUME } from '../DefaultResume';
import { Button, Switch } from '@mui/material';
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
} from '@material-ui/core';
import _ from 'lodash';
import { courseWorkArrToString, courseWorkStrToArr } from '../Util';
import { LOCAL_STORAGE_KEY_OFFLINE } from '../../config';
import { LOCAL_STORAGE_KEY_ONLINE } from '../../config';

const DEBOUNCE_MS = 500;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      margin: '20px',
      fontFamily: '"Nunito Sans", sans-serif',
    },
    field: {
      margin: '5px',
    },
  });
});

const Editor = (props: {
  compile: any;
  isReady: boolean;
  parentTemplate: string;
  setCompiledResult: any
}) => {
  const isPhone = useIsMobile();
  const { compile, isReady, parentTemplate, setCompiledResult } = props;
  const { isLoggedIn, token } = useContext(AuthContext);
  const [autoCompile, setAutoCompile] = useState(true);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const isMobile = useIsMobile({
    width: 1020,
  });
  const [userResume, setUserResume] = useState(
    JSON.parse(JSON.stringify(DEFAULT_RESUME)), // Make a copy so as to not interfere with original.
  );
  const classes = useStyles();

  useEffect(() => {
    console.log("initializing editor");
    if (isPhone) {
      alert(
        `For best experience, 
           please use a laptop or desktop device 
           and have a good internet connection 
           when using the LaTex Resume Builder tool.`,
      );
    }
    if (!isLoggedIn) return;
    console.log('User is logged in. Fetching resume from backend.');
    // Read from local storage to detect any offline edits.
    // If there is a key, ask user via alert if they wanna keep their local copy or overwrite it.
    const localCopy = localStorage.getItem(LOCAL_STORAGE_KEY_OFFLINE);
    if (localCopy && localCopy !== JSON.stringify(DEFAULT_RESUME)) {
      const overwrite = window.confirm(
        'We found some edits while you were logged out. Do you want to use this instead?',
      );
      if (overwrite) {
        console.log('Overwriting backend with offline copy.');
        const resumeSavedFromOfflineEdits = JSON.parse(localCopy);
        sync('POST', resumeSavedFromOfflineEdits); // Sync with backend.
        localStorage.setItem(LOCAL_STORAGE_KEY_ONLINE, localCopy); // Save to online local storage.
        localStorage.removeItem(LOCAL_STORAGE_KEY_OFFLINE); // Delete offline key.
        setUserResume(resumeSavedFromOfflineEdits); // Update UI.
        return;
      }
      // Otherwise, do nothing.
    }
    localStorage.removeItem(LOCAL_STORAGE_KEY_OFFLINE); // Always delete the offline key when online.
    let latestResume = localStorage.getItem(LOCAL_STORAGE_KEY_ONLINE); // Fetch latest resume from local storage.
    if (!latestResume) {
      console.log(
        'No local storage found. Fetching from backend, and saving it locally.',
      );
      const getLatestCopyFromDB = async () => {
        const data = await sync('GET', {});
        if (data) {
          const resume = data.resume;
          localStorage.setItem(
            // Save fetched resume to local storage.
            LOCAL_STORAGE_KEY_ONLINE,
            JSON.stringify(resume),
          );
          setUserResume(resume); // Update UI.
        }
      };
      getLatestCopyFromDB();
    } else {
      console.log(
        'Using the latest copy from online local storage, and syncing it with the backend.',
      );
      const latestResumeFromLocalStorage = JSON.parse(latestResume);
      sync('POST', latestResumeFromLocalStorage); // Sync with backend the current local copy.
      setUserResume(latestResumeFromLocalStorage); // Update UI.
    }
  }, [isLoggedIn]);

  useEffect(() => {
    compile(userResume, setCompiledResult);
  }, [isReady, userResume]);

  const saveAndMaybeCompile = () => {
    save();
    if (autoCompile) compile(userResume, setCompiledResult);
  };

  const sync = async (operation: string, upToDateResume: any) => {
    let responseData = null;
    setIsLoadingSave(true);
    try {
      if (operation === 'GET') {
        let res = await fetch('/api/util/resume', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
        responseData = await res.json();
        setIsLoadingSave(false);
        return responseData;
      }
      let res = await fetch('/api/util/resume', {
        method: operation,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          resume: upToDateResume,
        }),
      });
      let data = await res.json();
      if (!res.ok) {
        throw new Error(data.message || 'Something went wrong!');
      }
      responseData = data;
    } catch (err) {
      console.log('Error saving to backend. More details: ' + err);
    }
    setIsLoadingSave(false);
    return responseData;
  };

  const save = async (postToBackend: boolean = false) => {
    if (isLoggedIn && postToBackend) {
      // Save to backend.
      console.log('Saved to backend.');
      let result = await sync('POST', userResume);
      alert(result.message);
    } else if (isLoggedIn) {
      // Save to "online" local storage.
      localStorage.setItem(
        LOCAL_STORAGE_KEY_ONLINE,
        JSON.stringify(userResume),
      );
      console.log('Saved to local storage online.');
    } else {
      // Save to "offline" local storage.
      localStorage.setItem(
        LOCAL_STORAGE_KEY_OFFLINE,
        JSON.stringify(userResume),
      );
      console.log('Saved to local storage offline.');
    }
  };

  const handleEditOrder = (e: any) => {
    const newOrder = e.target.value;
    let newOrderArr = newOrder.replaceAll(' ', '').split(',');
    userResume.order = newOrderArr;
    saveAndMaybeCompile();
  };

  const handleProfileEdit = (e: any) => {
    const { id, value } = e.target;
    userResume.profile[id] = value;
    saveAndMaybeCompile();
  };

  const handleSkillsEdit = (e: any) => {
    const { id, value } = e.target;
    userResume.technical_skills[id] = value;
    saveAndMaybeCompile();
  };

  // Handler when the user clicks on 'compile.'
  const clicksOnCompile = () => {
    save(true); // This call will make a POST request to the backend.
    compile(userResume, setCompiledResult);
  };

  const bulletPointToString = (bulletPoints: Array<string>) => {
    let str = '';
    for (let bulletPt of bulletPoints) {
      str += ' >>> ' + bulletPt + ';\n\n';
    }
    return str.replaceAll('\\', '');
  };

  const bulletPointStringToArr = (bulletPointStr: string): Array<string> => {
    bulletPointStr = bulletPointStr
      .replaceAll('\\', '')
      .replaceAll('$', '\\$')
      .replaceAll('%', '\\%');

    const regex = />>>(.+?);/g;
    const matches = bulletPointStr.match(regex);
    if (matches) {
      const content = matches.map((match) => match.replace(/>>>(.+?);/g, '$1'));
      return content;
    }
    return [];
  };

  const editSection = (index: number, e: any, section: string) => {
    const { id, value } = e.target;
    if (id === 'bullet_points') {
      let result = bulletPointStringToArr(value);
      if (result.length > 0) {
        console.log('result: ', result);
        userResume[section][index][id] = result;
      } else {
        alert('You need to have at least one bullet point.');
      }
      return;
    }
    userResume[section][index][id] = value;
    saveAndMaybeCompile();
  };

  const editCourseWork = (e: any) => {
    const { value } = e.target;
    const coursesArr = courseWorkStrToArr(value);
    if (coursesArr.length > 0) {
      userResume.coursework = coursesArr;
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          margin: '15px',
          borderStyle: 'solid',
          borderWidth: 'thick',
          width: isMobile ? '90%' : '45%',
          float: 'none',
        }}
      >
        <Button
          onClick={clicksOnCompile}
          variant="contained"
          color={isLoggedIn ? 'primary' : 'warning'}
          style={{
            marginLeft: '10px',
            marginTop: '20px',
            marginBottom: '10px',
          }}
          disabled={isLoadingSave}
        >
          {isLoggedIn ? <b>Save + compile</b> : <b>Compile without saving</b>}
        </Button>
        {/* <p>Autocompile: </p> */}
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              onChange={() => {
                setAutoCompile(!autoCompile);
              }}
            />
          }
          label="Autocompile"
          style={{ marginLeft: '10px' }}
        ></FormControlLabel>

        <a
          href="/content/get-interview/build-best-resume"
          style={{ marginLeft: '10px' }}
        >
          Tutorial
        </a>
        {!isLoggedIn ? (
          <p
            style={{ margin: '10px', fontFamily: '"Nunito Sans", sans-serif' }}
          >
            Since you aren't logged in, your progress isn't being saved. Log in
            to save what you have, or lose it when you refresh.{' '}
          </p>
        ) : (
          <p
            style={{ margin: '10px', fontFamily: '"Nunito Sans", sans-serif' }}
          >
            Your changes are ONLY cached locally, be sure to click on "SAVE + COMPILE" to sync your changes with us.
            The cache gets cleared when you log out.
          </p>
        )}
      </div>

      <div
        style={{
          borderStyle: 'solid',
          borderWidth: 'thick',
          width: isMobile ? '90%' : '45%',
          height: '900px',
          float: isMobile ? 'none' : 'left',
          margin: '15px',
          overflow: 'scroll',
        }}
      >
        <div className={classes.container}>
          <h1>Edit your information here:</h1>
          <TextField
            variant="outlined"
            label="Resume Sections"
            className={classes.field}
            onChange={_.debounce(handleEditOrder, DEBOUNCE_MS)}
            id="order"
            defaultValue={`work_experience, education, projects, technical_skills`}
            style={{ width: '100%' }}
          ></TextField>
          <h1>Profile</h1>
          <Card>
            <CardContent>
              <TextField
                variant="outlined"
                label="Full name"
                key={userResume.profile['__full_name__']}
                className={classes.field}
                onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                id="__full_name__"
                defaultValue={userResume.profile['__full_name__']}
              ></TextField>
              <TextField
                variant="outlined"
                label="Email address"
                key={userResume.profile['__email__']}
                className={classes.field}
                onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                id="__email__"
                defaultValue={userResume.profile['__email__']}
              ></TextField>
              <TextField
                variant="outlined"
                label="Github URL"
                key={userResume.profile['__github__']}
                className={classes.field}
                onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                id="__github__"
                defaultValue={userResume.profile['__github__']}
              ></TextField>

              <TextField
                variant="outlined"
                label="LinkedIn URL"
                key={userResume.profile['__linkedin__']}
                className={classes.field}
                onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                id="__linkedin__"
                defaultValue={userResume.profile['__linkedin__']}
              ></TextField>
              <TextField
                variant="outlined"
                label="Phone number"
                key={userResume.profile['__phone_number__']}
                className={classes.field}
                onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                id="__phone_number__"
                defaultValue={userResume.profile['__phone_number__']}
              ></TextField>

              {(parentTemplate === 'fang' || parentTemplate === 'russ') && (
                <TextField
                  variant="outlined"
                  label="You are based in"
                  key={userResume.profile['__location__']}
                  className={classes.field}
                  onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                  id="__location__"
                  defaultValue={userResume.profile['__location__']}
                ></TextField>
              )}

              {(parentTemplate === 'fang' ||
                parentTemplate === 'russ' ||
                parentTemplate === 'deedy') && (
                <TextField
                  variant="outlined"
                  label="Personal website"
                  key={userResume.profile['__personal_website__']}
                  className={classes.field}
                  onChange={_.debounce(handleProfileEdit, DEBOUNCE_MS)}
                  id="__personal_website__"
                  defaultValue={userResume.profile['__personal_website__']}
                ></TextField>
              )}
            </CardContent>
          </Card>
          <br></br>
          <h1>Work Experience</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              userResume.work_experience.unshift({
                __company__: 'company',
                __title__: 'position',
                __dates__: 'dates',
                __location__: 'location',
                bullet_points: ['you need at least 1 bullet point.'],
              });
              setUserResume(JSON.parse(JSON.stringify(userResume)));
            }}
          >
            Add Experience
          </Button>
          <br></br>
          {userResume.work_experience.map((work: any, index: number) => {
            return (
              <Card style={{ marginTop: '10px' }}>
                <CardContent>
                  <TextField
                    key={work['__company__']}
                    variant="outlined"
                    label="Company"
                    id={`__company__`}
                    defaultValue={work['__company__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'work_experience'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Title"
                    id={`__title__`}
                    defaultValue={work['__title__']}
                    key={work['__title__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'work_experience'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Dates"
                    id={`__dates__`}
                    defaultValue={work['__dates__']}
                    key={work['__dates__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'work_experience'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Location"
                    id={`__location__`}
                    defaultValue={work['__location__']}
                    key={work['__location__']}
                    style={{ margin: '5px' }}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'work_experience'),
                      DEBOUNCE_MS,
                    )}
                  ></TextField>
                  <h3>Bullet points: </h3>
                  <p>
                    A bullet point starts with <b>{'>>>'}</b> and ends with{' '}
                    <b>;</b> (Autocompile is disabled for bullet points.)
                  </p>
                  <textarea
                    style={{ width: '100%', height: '120px' }}
                    defaultValue={bulletPointToString(work['bullet_points'])}
                    key={work['bullet_points']}
                    id="bullet_points"
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'work_experience'),
                      DEBOUNCE_MS,
                    )}
                  ></textarea>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (userResume.work_experience.length === 1) {
                        alert('You must have at least 1 item.');
                        return;
                      }
                      userResume.work_experience.splice(index, 1);
                      setUserResume(JSON.parse(JSON.stringify(userResume)));
                    }}
                  >
                    Delete
                  </Button>
                </CardContent>
              </Card>
            );
          })}
          

          <h1>Education</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              userResume.education.unshift({
                __school__: 'school',
                __degree__: 'Bsc. your major',
                __dates__: 'dates',
                __location__: 'location',
                __grade__: 'gpa, u can leave blank if its low',
                bullet_points: ['you need at least 1 bullet point.'],
              });
              setUserResume(JSON.parse(JSON.stringify(userResume)));
            }}
          >
            Add Education
          </Button>
          <br></br>
          {userResume.education.map((school: any, index: number) => {
            return (
              <Card style={{ marginTop: '10px' }}>
                <CardContent>
                  <TextField
                    key={school['__school__']}
                    variant="outlined"
                    label="School"
                    id={`__school__`}
                    defaultValue={school['__school__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    key={school['__degree__']}
                    variant="outlined"
                    label="Degree / Major"
                    id={`__degree__`}
                    defaultValue={school['__degree__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Grade"
                    id={`__grade__`}
                    defaultValue={school['__grade__']}
                    key={school['__grade__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Dates"
                    id={`__dates__`}
                    defaultValue={school['__dates__']}
                    key={school['__dates__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>
                  <TextField
                    variant="outlined"
                    label="Location"
                    id={`__location__`}
                    defaultValue={school['__location__']}
                    key={school['__location__']}
                    style={{ margin: '5px' }}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                  ></TextField>
                  <h3>Bullet points: </h3>
                  <p>
                    A bullet point starts with <b>{'>>>'}</b> and ends with{' '}
                    <b>;</b> (Autocompile is disabled for bullet points.)
                  </p>
                  <textarea
                    style={{ width: '100%', height: '120px' }}
                    defaultValue={bulletPointToString(school['bullet_points'])}
                    key={school['bullet_points']}
                    id="bullet_points"
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'education'),
                      DEBOUNCE_MS,
                    )}
                  ></textarea>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (userResume.education.length === 1) {
                        alert('You must have at least 1 item.');
                        return;
                      }
                      userResume.education.splice(index, 1);
                      setUserResume(JSON.parse(JSON.stringify(userResume)));
                    }}
                  >
                    Delete
                  </Button>
                </CardContent>
              </Card>
            );
          })}
          

          <h1>Projects</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              userResume.projects.unshift({
                __name__: 'project name',
                __technologies__: 'Python, Java...',
                __project_link__: 'https://google.com',
                bullet_points: ['you need at least 1 bullet point.'],
              });
              setUserResume(JSON.parse(JSON.stringify(userResume)));
            }}
          >
            Add Project
          </Button>
          <br></br>
          {userResume.projects.map((project: any, index: number) => {
            return (
              <Card style={{ marginTop: '10px' }}>
                <CardContent>
                  <TextField
                    key={project['__name__']}
                    variant="outlined"
                    label="Name"
                    id={`__name__`}
                    defaultValue={project['__name__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'projects'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>

                  <TextField
                    key={project['__project_link__']}
                    variant="outlined"
                    label="Project Link"
                    id={`__project_link__`}
                    defaultValue={project['__project_link__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'projects'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>

                  <TextField
                    key={project['__technologies__']}
                    variant="outlined"
                    label="Technologies"
                    id={`__technologies__`}
                    defaultValue={project['__technologies__']}
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'projects'),
                      DEBOUNCE_MS,
                    )}
                    style={{ margin: '5px' }}
                  ></TextField>

                  <h3>Bullet points: </h3>
                  <p>
                    A bullet point starts with <b>{'>>>'}</b> and ends with{' '}
                    <b>;</b> (Autocompile is disabled for bullet points.)
                  </p>
                  <textarea
                    style={{ width: '100%', height: '120px' }}
                    defaultValue={bulletPointToString(project['bullet_points'])}
                    key={project['bullet_points']}
                    id="bullet_points"
                    onChange={_.debounce(
                      (e) => editSection(index, e, 'projects'),
                      DEBOUNCE_MS,
                    )}
                  ></textarea>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (userResume.projects.length === 1) {
                        alert('You must have at least 1 item.');
                        return;
                      }
                      userResume.projects.splice(index, 1);
                      setUserResume(JSON.parse(JSON.stringify(userResume)));
                    }}
                  >
                    Delete
                  </Button>
                </CardContent>
              </Card>
            );
          })}
          
          

          <br></br>
          <h1>Technical Skills</h1>
          <Card>
            <CardContent>
              <TextField
                variant="outlined"
                label="Programming Languages"
                key={userResume.technical_skills['__programming_languages__']}
                className={classes.field}
                onChange={_.debounce(handleSkillsEdit, DEBOUNCE_MS)}
                style={{ width: '100%' }}
                id="__programming_languages__"
                defaultValue={
                  userResume.technical_skills['__programming_languages__']
                }
              ></TextField>

              <TextField
                variant="outlined"
                label="Technologies"
                key={userResume.technical_skills['__technologies__']}
                style={{ width: '100%' }}
                className={classes.field}
                onChange={_.debounce(handleSkillsEdit, DEBOUNCE_MS)}
                id="__technologies__"
                defaultValue={userResume.technical_skills['__technologies__']}
              ></TextField>
            </CardContent>
          </Card>

          {parentTemplate === 'deedy' && (
            <React.Fragment>
              <br></br>
              <h1>Course work</h1>
              <Card>
                <CardContent>
                  <p>
                    Each course is like a bullet point. Each course has a course
                    code and a course description. The course code is separated
                    by a colon from the course description. Again,
                    auto-compilation is turned off for this section.
                  </p>
                  <p>
                    Format for each course:
                    <br></br>
                    <b>{'>>>'} course_code : course_description;</b>
                  </p>
                  <textarea
                    style={{ width: '100%', height: '120px' }}
                    defaultValue={courseWorkArrToString(userResume.coursework)}
                    id="coursework"
                    key={userResume.coursework}
                    onChange={_.debounce(editCourseWork, DEBOUNCE_MS)}
                  ></textarea>
                </CardContent>
              </Card>
              <br></br>
              <h1>Extra curriculars</h1>
              <br></br>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  userResume.awards.unshift({
                    __title__: 'Extra curricular title',
                    bullet_points: ['you need at least 1 bullet point.'],
                  });
                  setUserResume(JSON.parse(JSON.stringify(userResume)));
                }}
              >
                Add Extra-Curricular
              </Button>
              {userResume.awards.map((award: any, index: number) => {
                return (
                  <Card style={{ marginTop: '10px' }}>
                    <CardContent>
                      <TextField
                        key={award['__title__']}
                        variant="outlined"
                        label="Extra curricular activity"
                        id={`__title__`}
                        defaultValue={award['__title__']}
                        onChange={_.debounce(
                          (e) => editSection(index, e, 'award'),
                          DEBOUNCE_MS,
                        )}
                        style={{ margin: '5px' }}
                      ></TextField>
                      <h3>Bullet points: </h3>
                      <p>
                        A bullet point starts with <b>{'>>>'}</b> and ends with{' '}
                        <b>;</b> (Autocompile is disabled for bullet points.)
                      </p>
                      <textarea
                        style={{ width: '100%', height: '120px' }}
                        defaultValue={bulletPointToString(
                          award['bullet_points'],
                        )}
                        key={award['bullet_points']}
                        id="bullet_points"
                        onChange={_.debounce(
                          (e) => editSection(index, e, 'awards'),
                          DEBOUNCE_MS,
                        )}
                      ></textarea>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (userResume.awards.length === 1) {
                            alert('You must have at least 1 item.');
                            return;
                          }
                          userResume.awards.splice(index, 1);
                          setUserResume(JSON.parse(JSON.stringify(userResume)));
                        }}
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                );
              })}
              
              
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Editor;
