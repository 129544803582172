/**
 * Defines the general structure of the entire course, in terms of [resource id].
 */

export const GET_THE_EXPERIENCE = {
  google_photos_clone: [
    {url_path: 'all-content', video_id: 'ge-fs2-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'ge-fs2-2', title: 'Agile development fundamentals'},
    {url_path: 'all-content', video_id: 'ge-fs2-3', title: 'Object oriented design fundamentals'},
    {url_path: 'all-content', video_id: 'ge-fs2-4', title: 'Server vs. Client based sessions'},
    {url_path: 'all-content', video_id: 'ge-fs2-5', title: 'Project overview'},
    {url_path: 'all-content', video_id: 'ge-fs2-6', title: 'Problem introduction'},
    {url_path: 'all-content', video_id: 'ge-fs2-7', title: 'Architecture design walkthrough'},
    {url_path: 'all-content', video_id: 'ge-fs2-8', title: 'Creating the scaffolding'},
    {url_path: 'all-content', video_id: 'ge-fs2-9', title: 'Setting up automated testing'},
    {url_path: 'all-content', video_id: 'ge-fs2-10', title: 'Bootstrapping the app'},
    {url_path: 'all-content', video_id: 'ge-fs2-11', title: 'Building the frontend: Basic styling'},
    {url_path: 'all-content', video_id: 'ge-fs2-12', title: 'Building the backend: Core API design'},
    {url_path: 'all-content', video_id: 'ge-fs2-13', title: 'Building the backend: OAuth'},
    {url_path: 'all-content', video_id: 'ge-fs2-14', title: 'Building the backend: Databases'},
    {url_path: 'all-content', video_id: 'ge-fs2-15', title: 'Routing with RESTApi in Next.js'},
    {url_path: 'all-content', video_id: 'ge-fs2-16', title: 'Setting up ML infrastructure'},
    {url_path: 'all-content', video_id: 'ge-fs2-17', title: 'Running inference using ML'},
    {url_path: 'all-content', video_id: 'ge-fs2-18', title: 'Create search indexes'},
    {url_path: 'all-content', video_id: 'ge-fs2-19', title: 'Hooking everything up together'},
    {url_path: 'all-content', video_id: 'ge-fs2-20', title: 'Wrap up'},
  ],
  digital_flipbook_player: [
    {url_path: 'all-content', video_id: 'ge-fs1-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'ge-fs1-2', title: 'Web technologies and the OCI stack'},
    {url_path: 'all-content', video_id: 'ge-fs1-3', title: 'Fundamentals of Javascript / Typescript'},
    {url_path: 'all-content', video_id: 'ge-fs1-4', title: 'Fundamentals of React'},
    {url_path: 'all-content', video_id: 'ge-fs1-5', title: 'Using CSS in React'},
    {url_path: 'all-content', video_id: 'ge-fs1-6', title: 'Problem overview'},
    {url_path: 'all-content', video_id: 'ge-fs1-7', title: 'Our approach: Design decisions'},
    {url_path: 'all-content', video_id: 'ge-fs1-8', title: 'Our approach: Tradeoffs and other considerations'},
    {url_path: 'all-content', video_id: 'ge-fs1-9', title: 'Our approach: Coding it out part 1'},
    {url_path: 'all-content', video_id: 'ge-fs1-10', title: 'Our approach: Coding it out part 2'},
  ],
  graph_executor: [
    {url_path: 'all-content', video_id: 'ge-ag1-1', title: 'Problem introduction'},
    {url_path: 'all-content', video_id: 'ge-ag1-2', title: 'Mapping out approaches and visualizations'},
    {url_path: 'all-content', video_id: 'ge-ag1-3', title: 'Tradeoffs and other considerations with various approaches'},
    {url_path: 'all-content', video_id: 'ge-ag1-4', title: 'Our approach: architecture design walkthrough'},
    {url_path: 'all-content', video_id: 'ge-ag1-5', title: 'Our approach: Preparing the graph'},
    {url_path: 'all-content', video_id: 'ge-ag1-6', title: 'Our approach: Implementing the executor part 1'},
    {url_path: 'all-content', video_id: 'ge-ag1-7', title: 'Our approach: Implementing the executor part 2'},
    {url_path: 'all-content', video_id: 'ge-ag1-8', title: 'Our approach: Implementing the executor part 3'},
    {url_path: 'all-content', video_id: 'ge-ag1-9', title: 'Performance bench marking'},
    {url_path: 'all-content', video_id: 'ge-ag1-10', title: 'Submitting YOUR solution write-ups (better performance = cash prize!)'},
  ],
  neural_network: [
    {url_path: 'all-content', video_id: 'ge-ag2-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'ge-ag2-2', title: 'Artificial intelligence in a nutshell'},
    {url_path: 'all-content', video_id: 'ge-ag2-3', title: 'Machine learning crash course'},
    {url_path: 'all-content', video_id: 'ge-ag2-4', title: 'Basic Math for ML'},
    {url_path: 'all-content', video_id: 'ge-ag2-5', title: 'Intro to linear models'},
    {url_path: 'all-content', video_id: 'ge-ag2-6', title: 'Intro to the perceptron'},
    {url_path: 'all-content', video_id: 'ge-ag2-7', title: 'Setting up the model'},
    {url_path: 'all-content', video_id: 'ge-ag2-8', title: 'Loading the data'},
    {url_path: 'all-content', video_id: 'ge-ag2-9', title: 'Learning the weights with back propagation'},
    {url_path: 'all-content', video_id: 'ge-ag2-10', title: 'Deploying the model in production'},
  ],
  combination: [
    {url_path: 'all-content', video_id: 'ge-c-1', title: 'Integrating neural networks and graph executor part 1'},
    {url_path: 'all-content', video_id: 'ge-c-2', title: 'Integrating neural networks and graph executor part 2'},
    {url_path: 'all-content', video_id: 'ge-c-3', title: 'Integrating neural networks and graph executor part 3'},
    {url_path: 'all-content', video_id: 'ge-c-4', title: 'Replacing mask rcnn with your neural network part 1'},
    {url_path: 'all-content', video_id: 'ge-c-5', title: 'Replacing mask rcnn with your neural network part 2'},
    {url_path: 'all-content', video_id: 'ge-c-6', title: 'Replacing mask rcnn with your neural network part 3'},
  ],
  low_level_systems: [
    {url_path: 'all-content', video_id: 'ge-lls-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'ge-lls-2', title: 'Memory model of the computer'},
    {url_path: 'all-content', video_id: 'ge-lls-3', title: 'RAM deep dive'},
    {url_path: 'all-content', video_id: 'ge-lls-4', title: 'Heap vs Stacks'},
    {url_path: 'all-content', video_id: 'ge-lls-5', title: 'Function call stack frames'},
    {url_path: 'all-content', video_id: 'ge-lls-6', title: 'CPU Caches'},
    {url_path: 'all-content', video_id: 'ge-lls-7', title: 'Cache localization'},
    {url_path: 'all-content', video_id: 'ge-lls-8', title: 'Memory accesses'},
    {url_path: 'all-content', video_id: 'ge-lls-9', title: 'Static vs. Dynamic memory allocation'},
    {url_path: 'all-content', video_id: 'ge-lls-10', title: 'Garbage collection'},
    {url_path: 'all-content', video_id: 'ge-lls-11', title: 'Basics of assembly'},
    {url_path: 'all-content', video_id: 'ge-lls-12', title: 'Problem introduction'},
    {url_path: 'all-content', video_id: 'ge-lls-13', title: 'Our approach: How to optimize assembly code'},
    {url_path: 'all-content', video_id: 'ge-lls-14', title: 'Other approaches'},
  ],
  __additional_metadata__: {},
};

export const GET_THE_INTERVIEW = {
  cracking_the_oa: [
    {url_path: 'all-content', video_id: 'gi-ctoa-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'gi-ctoa-2', title: 'The general coding assessment structure'},
    {url_path: 'all-content', video_id: 'gi-ctoa-3', title: 'Common patterns in the general assessment part 1'},
    {url_path: 'all-content', video_id: 'gi-ctoa-4', title: 'Common patterns in the general assessment part 2'},
    {url_path: 'all-content', video_id: 'gi-ctoa-5', title: 'Common patterns in the general assessment part 3'},
    {url_path: 'all-content', video_id: 'gi-ctoa-6', title: 'Common patterns in the general assessment part 4'},
    {url_path: 'all-content', video_id: 'gi-ctoa-7', title: 'Common problems and pitfalls on coding assessment platforms'},
    {url_path: 'all-content', video_id: 'gi-ctoa-8', title: 'Problem solving techniques for when you are stuck part 1'},
    {url_path: 'all-content', video_id: 'gi-ctoa-9', title: 'Problem solving techniques for when you are stuck part 2'},
  ],
  building_best_resume: [
    {url_path: 'free-content', video_id: 'gi-bbr-1', title: 'How to use resume builder tool (free)'},
    {url_path: 'all-content', video_id: 'gi-bbr-2', title: 'Personal websites and branding'},
    {url_path: 'all-content', video_id: 'gi-bbr-3', title: 'How you should organize your resume (case by case)'},
    {url_path: 'all-content', video_id: 'gi-bbr-4', title: 'Optimal bullet point structure'},
    {url_path: 'all-content', video_id: 'gi-bbr-5', title: 'How to maximize resume value in your current role'},
    {url_path: 'all-content', video_id: 'gi-bbr-6', title: 'Most realistic path to getting interview calls in your position (L1 - L6)'},
    {url_path: 'all-content', video_id: 'gi-bbr-7', title: 'Getting the interview with local tech companies (if you are L3*)'},
    {url_path: 'all-content', video_id: 'gi-bbr-8', title: 'Getting the interview with FAANG+ companies (if you are L5-6*)'},

  ],
  __additional_metadata__: {},
};

export const GET_THE_OFFER = {
  fundamental_data_structures_algorithms: [
    {url_path: 'all-content', video_id: 'go-fdsa-1', title: 'Introduction'},
    {url_path: 'all-content', video_id: 'go-fdsa-2', title: 'Big O notation'},
    {url_path: 'all-content', video_id: 'go-fdsa-3', title: 'Arrays and RAM'},
    {url_path: 'all-content', video_id: 'go-fdsa-4', title: 'Linked lists'},
    {url_path: 'all-content', video_id: 'go-fdsa-5', title: 'Hashmap: implementations and usage'},
    {url_path: 'all-content', video_id: 'go-fdsa-6', title: 'Containers: nested vs. flattened representations'},
    {url_path: 'all-content', video_id: 'go-fdsa-7', title: 'Sorting: Basic quadratic time techniques'},
    {url_path: 'all-content', video_id: 'go-fdsa-8', title: 'Sorting: Quick sort'},
    {url_path: 'all-content', video_id: 'go-fdsa-9', title: 'Sorting: Merge sort & External merge sort'},
    {url_path: 'all-content', video_id: 'go-fdsa-10', title: 'Sorting: Can we get linear?'},
    {url_path: 'all-content', video_id: 'go-fdsa-11', title: 'Linked lists overview'},
    {url_path: 'all-content', video_id: 'go-fdsa-12', title: 'Applications of linked lists: Dequeue'},
    {url_path: 'all-content', video_id: 'go-fdsa-13', title: 'Heap & Priority queue overview'},
    {url_path: 'all-content', video_id: 'go-fdsa-14', title: 'Heap operations'},
    {url_path: 'all-content', video_id: 'go-fdsa-15', title: 'Trees overview'},
    {url_path: 'all-content', video_id: 'go-fdsa-16', title: 'Binary search trees'},
    {url_path: 'all-content', video_id: 'go-fdsa-17', title: 'Tree traversals: BFS, recursive & iterative'},
    {url_path: 'all-content', video_id: 'go-fdsa-18', title: 'Tree traversals: DFS, recursive & iterative'},
    {url_path: 'all-content', video_id: 'go-fdsa-19', title: 'Graphs overview'},
    {url_path: 'all-content', video_id: 'go-fdsa-20', title: 'Graph traversals: BFS & DFS, recursive & iterative'},
    {url_path: 'all-content', video_id: 'go-fdsa-21', title: 'Graph traversals: Backtracking'},
    {url_path: 'all-content', video_id: 'go-fdsa-22', title: 'Graph operations: topological sort'},
    {url_path: 'all-content', video_id: 'go-fdsa-23', title: 'Weighted graph traversals: dijkstras & prims'},
    {url_path: 'all-content', video_id: 'go-fdsa-24', title: 'Weighted graph traversals: bellman ford'},
    {url_path: 'all-content', video_id: 'go-fdsa-25', title: 'Weighted graph traversals: kruskals'},
  ],

  core_coding_interview_patterns: [
    {url_path: 'all-content', video_id: 'go-ccip-1', title: 'Sliding window'},
    {url_path: 'all-content', video_id: 'go-ccip-2', title: 'Two pointers'},
    {url_path: 'all-content', video_id: 'go-ccip-3', title: 'Fast and slow pointers'},
    {url_path: 'all-content', video_id: 'go-ccip-4', title: 'Merge intervals'},
    {url_path: 'all-content', video_id: 'go-ccip-5', title: 'Cyclic sort'},
    {url_path: 'all-content', video_id: 'go-ccip-6', title: 'In-place reversal of a linked list'},
    {url_path: 'all-content', video_id: 'go-ccip-7', title: 'Tree breadth first search'},
    {url_path: 'all-content', video_id: 'go-ccip-8', title: 'Tree depth first search'},
    {url_path: 'all-content', video_id: 'go-ccip-9', title: 'Two heaps'},
    {url_path: 'all-content', video_id: 'go-ccip-10', title: 'Subsets'},
    {url_path: 'all-content', video_id: 'go-ccip-11', title: 'Modified binary search'},
    {url_path: 'all-content', video_id: 'go-ccip-12', title: 'Bitwise XOR'},
    {url_path: 'all-content', video_id: 'go-ccip-13', title: 'Top K elements'},
    {url_path: 'all-content', video_id: 'go-ccip-14', title: 'K-way merge'},
    {url_path: 'all-content', video_id: 'go-ccip-15', title: '0/1 Knapsack (Dynamic Programming)'},
    {url_path: 'all-content', video_id: 'go-ccip-16', title: 'Unbounded Knapsack (Dynamic Programming)'},
  ],
  system_design: [
    {url_path: 'all-content', video_id: 'go-sd-1', title: 'Anatomy of a system design interview'},
    {url_path: 'all-content', video_id: 'go-sd-2', title: 'Load balancing: overview'},
    {url_path: 'all-content', video_id: 'go-sd-3', title: 'Load balancing: algorithms'},
    {url_path: 'all-content', video_id: 'go-sd-4', title: 'Load balancing: tradeoffs'},
    {url_path: 'all-content', video_id: 'go-sd-5', title: 'Proxies'},
    {url_path: 'all-content', video_id: 'go-sd-6', title: 'Caching: overview'},
    {url_path: 'all-content', video_id: 'go-sd-7', title: 'Caching: CDNs'},
    {url_path: 'all-content', video_id: 'go-sd-8', title: 'Cache invalidation & eviction policies'},
    {url_path: 'all-content', video_id: 'go-sd-9', title: 'Database: overview'},
    {url_path: 'all-content', video_id: 'go-sd-10', title: 'Database: SQL vs. NoSQL'},
    {url_path: 'all-content', video_id: 'go-sd-11', title: 'Database: CAP theorem'},
    {url_path: 'all-content', video_id: 'go-sd-12', title: 'Database: ACID properties'},
    {url_path: 'all-content', video_id: 'go-sd-13', title: 'Database: Indexing'},
    {url_path: 'all-content', video_id: 'go-sd-14', title: 'Database: Sharding'},
    {url_path: 'all-content', video_id: 'go-sd-15', title: 'Database: Replication'},
    {url_path: 'all-content', video_id: 'go-sd-16', title: 'Database: Partitioning'},
    {url_path: 'all-content', video_id: 'go-sd-17', title: 'Case study: design tinyURL'},
    {url_path: 'all-content', video_id: 'go-sd-18', title: 'Case study: design instagram'},
  ],
  __additional_metadata__: {},
};
