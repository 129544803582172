import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../shared/Login/AuthContext';
import { useIsMobile } from '../../shared/UseIsMobile';
import { EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION } from '../../config';
import Editor from './Editor';

const Previewer = (props: any) => {
  const { compile, engineIsReady, pdfObject, parentTemplate, progress } = props;
  const [compiledResult, setCompiledResult] = useState('');
  const isMobile = useIsMobile({
    width: 1020,
  });
  const { isLoggedIn, proExpirationDate } = useContext(AuthContext);
  const canAccess =
    isLoggedIn &&
    new Date(proExpirationDate) >=
      new Date(EXPRESS_WAITLIST_TIER_DATE_REPRESENTATION);
  
  let status = 'loading the PDF engine. ';
  if (progress) {
    status = 'Progress: ' + progress + '%';
  }
  
  return (
    <React.Fragment>
      <Editor
        setCompiledResult={setCompiledResult}
        compile={compile}
        isReady={engineIsReady}
        parentTemplate={parentTemplate}
      />
      {pdfObject.length > 0 ? (
        <div>
          <embed
            src={pdfObject}
            width={isMobile ? '90%' : '47%'}
            height="900px"
            type="application/pdf"
            style={{
              borderStyle: 'solid',
              borderWidth: 'thick',
              float: isMobile ? 'none' : 'right',
              margin: '15px',
            }}
          ></embed>
        </div>
      ) : (
        <div
          style={{
            borderStyle: 'solid',
            borderWidth: 'thick',
            width: isMobile ? '90%' : '47%',
            height: '900px',
            float: isMobile ? 'none' : 'right',
            margin: '15px',
          }}
        >
          <div className="spinner"></div>
          <h3
            style={{
              fontFamily: '"Nunito Sans", sans-serif',
              textAlign: 'center',
            }}
          >
            {!engineIsReady ? status : 'compiling'}
          </h3>
        </div>
      )}
      <div style={{ margin: 'auto' }}>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            const dataStr =
              'data:text/plain;charset=utf-8,' +
              encodeURIComponent(compiledResult);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', dataStr);
            downloadAnchorNode.setAttribute('download', 'main.tex');
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          }}
          style={{ margin: '20px' }}
          disabled={compiledResult.length === 0 || !canAccess}
        >
          <b>Export main.tex</b>
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            window.location.href =
              '/pdf-engine/' + parentTemplate + '/assets.zip';
          }}
          disabled={compiledResult.length === 0 || !canAccess}
        >
          <b>Export assets</b>
        </Button>
        {compiledResult.length === 0 && canAccess && (
          <p
            style={{ fontFamily: '"Nunito Sans", sans-serif', marginLeft: '20px' }}
          >
            Please wait for PDF engine to load.
          </p>
        )}

        {!canAccess && (
          <p
            style={{ fontFamily: '"Nunito Sans", sans-serif', marginLeft: '20px' }}
          >
            <b>
              <a href="/enroll">
                Enroll to become a member or join the express waitlist to
                unlock exporting source!
              </a>
            </b>
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default Previewer;
