export const DEFAULT_RESUME = {
  order: [
    'work_experience',
    'education',
    'projects',
    'technical_skills',
  ],
  profile: {
    __full_name__: 'Jack Tech',
    __last_updated__: '6th November 2022',
    __email__: 'jack@jackhetech.io',
    __github__: 'https://github.com/',
    __linkedin__: 'https://linkedin.com/in/',
    __phone_number__: '+1 (000)-000-0000',
    __personal_website__: 'https://jackhetech.io',
    __location__: 'San Francisco, CA',
  },
  work_experience: [
    {
      __title__: 'Software Engineer Intern',
      __company__: 'Nuro',
      __dates__: 'May 2022 - Sep 2022',
      __location__: 'Mountain View, CA',
      bullet_points: [
        'I worked as a software engineer intern during the Summer of my junior year of college',
        'Nuro is an autonomous robotics company based in Silicon Valley',
        'Feel free to change these bullet points as you see fit with the resume builder',
        'How to write strong bullet points: https://realtechprep.com/content/get-interview/build-best-resume',
      ],
    },
    {
      __title__: 'Software Engineer Intern',
      __company__: 'Microsoft',
      __dates__: 'Sep 2022 - Dec 2022',
      __location__: 'Redmond, WA',
      bullet_points: [
        'I worked as a software engineer intern during the Fall of my senior year of college',
        'Microsoft is a global leader in cloud computing and operating systems',
        'Feel free to change these bullet points as you see fit with the resume builder',
        'How to write strong bullet points: https://realtechprep.com/content/get-interview/build-best-resume',
      ],
    },
    {
      __title__: 'Software Engineer Intern',
      __company__: 'Microsoft',
      __dates__: 'May 2021 - Aug 2021',
      __location__: 'Redmond, WA',
      bullet_points: [
        'I worked as a software engineer intern during the Summer of my sophomore year of college',
        'Microsoft is a global leader in cloud computing and operating systems',
        'Feel free to change these bullet points as you see fit with the resume builder',
        'How to write strong bullet points: https://realtechprep.com/content/get-interview/build-best-resume',
      ],
    },
    {
      __title__: 'Software Engineer Intern',
      __company__: 'Microsoft',
      __dates__: 'May 2020 - Aug 2020',
      __location__: 'Redmond, WA',
      bullet_points: [
        'I worked as a software engineer intern during the Summer of my freshman year of college',
        'Microsoft is a global leader in cloud computing and operating systems',
        'Feel free to change these bullet points as you see fit with the resume builder',
        'How to write strong bullet points: https://realtechprep.com/content/get-interview/build-best-resume',
      ],
    },
    {
      __title__: 'Undergraduate Teaching Assistant',
      __company__: 'UBC',
      __dates__: 'Sep 2020 - May 2021',
      __location__: 'Vancouver, BC',
      bullet_points: [
        'I worked as a teaching assistant for CPSC 121: Models of Computation. I helped students with discrete math assignments / graded exams.',
        'UBC is a college in Vancouver, BC.',
        'Feel free to change these bullet points as you see fit with the resume builder',
        'How to write strong bullet points: https://realtechprep.com/content/get-interview/build-best-resume',
      ],
    },
  ],
  education: [
    {
      __school__: 'University of British Columbia',
      __degree__: 'Bsc. Computer Science',
      __location__: 'Vancouver, BC',
      __grade__: 'GPA: 3.8 / 4.0',
      __dates__: 'Sep 2019 - May 2023 (your grad or expected grad date)',
      bullet_points: [
        'Object Oriented Design, Data Structures and Algorithms, Computer Systems',
      ],
    },
  ],
  coursework: [
    {
      __course__: 'CPSC 121', 
      __descript__: 'Models of Computation'
    },
    {
      __course__: 'CPSC 110',
      __descript__: 'Functional program design'
    }, 
    {
      __course__: 'CPSC 210',
      __descript__: 'Software Construction'
    }, 
    {
      __course__: 'CPSC 221',
      __descript__: 'Data structures and algorithms'
    }, 
    {
      __course__: 'CPSC 310',
      __descript__: 'Software design and development'
    }, 
    {
      __course__: 'CPSC 313',
      __descript__: 'Introduction to computer systems'
    }, 
    {
      __course__: 'DSCI 100',
      __descript__: 'Introduction to data science'
    }, 
    {
      __course__: 'CPSC 320',
      __descript__: 'Advanced algorithms'
    }, 
    {
      __course__: 'CPSC 340',
      __descript__: 'Machine learning'
    }, {
      __course__: 'CPSC 425',
      __descript__: 'Computer vision'
    }
  ],
  awards: [
    {
      __title__: 'Finalist - HackTech 2020', 
      bullet_points: [
        'Best entertainment hack'
      ]
    }, 
    {
      __title__: 'Winner - GOA 2019',
      bullet_points: [
        'Most technically complex'
      ]
    }, 
    {
      __title__: 'Scholarship',
      bullet_points: [
        'Artona High School Scholarship'
      ]
    }
  ],
  technical_skills: {
    __programming_languages__: 'Python, Java, JavaScript, TypeScript, C/C++, Go, Bash, SQL, R, HTML/CSS',
    __technologies__: 'React, Node, Express, Flask, Mongo, Git, Docker, K8s, AWS, Azure, GCP, Bazel'
  },
  projects: [
    {
      __name__: 'Multiplayer Chess Game',
      __technologies__: 'Node.js, Express, Nginx, React, MongoDB, Docker, Python',
      bullet_points: [
        'A multiplayer chess game, where users can play against each other in real time through a shared URL.',
      ],
      __project_link__: "https://github.com/JackHeTech/multiplayer-chess-game"
    },
  ],
};
