import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import HoverCard from '../shared/HoverCard/HoverCard';
import { useIsMobile } from '../shared/UseIsMobile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flexGrow: 1,
      display: 'flex',
      margin: '30px',
    },
    column: {
      margin: '10px',
    },
  }),
);

const FeatureSection = (props: { rowData: Array<any> }) => {
  const classes = useStyles();
  const screenTooNarrow = useIsMobile({
    width: 1618
  });

  const isMobile = useIsMobile();
  return (
    <div className={(screenTooNarrow) ? classes.column : classes.row}>
      {props.rowData.map((item, index) => {
        return (
          <HoverCard
            projectTitle={item.title}
            projectDescription={item.description}
            projectVideoId={"Zb0EJkHHxgo"}
            shortDescription={item.shortDescription}
            buttonText={item.buttonText}
            buttonCallback={item.buttonCallback}
            img={item.img}
            isMobileView={isMobile}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default FeatureSection;
