import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Resume from './resume/Resume';
import Content from './content/Content';
import Enroll from './enroll/Enroll';
import Landing from './landing/Landing';
import NavigationBar from './shared/Navigation/NavigationBar';
import { AuthContext } from './shared/Login/AuthContext';
import { useAuth } from './shared/Login/AuthHook';
import NextSteps from './enroll/NextSteps';
import Footer from './shared/Footer/Footer';
import Terms from './legal/terms';
import Privacy from './legal/privacy';
import {
  GetTheExperience,
  GetTheInterview,
  GetTheOffer,
  FullStackApp1,
  FullStackApp2,
  AlgoApp1,
  AlgoApp2,
  ComboApp, 
  LowLevelApp, 
  CrackingOA, 
  BuildBestResume, 
  FundamentalDSA, 
  CodingPatterns,
  SystemDesign,
} from './content/ContentPages';
import Profile from './profile/Profile';

function App() {
  const {
    token,
    login,
    logout,
    name,
    accountType,
    email,
    githubUsername,
    proExpirationDate,
    image
  } = useAuth();
  console.log('IsLoggedIn: ' + !!token);
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        name: name,
        accountType: accountType,
        email: email,
        githubUsername: githubUsername,
        proExpirationDate: proExpirationDate,
        login: login,
        logout: logout,
        image: image
      }}
    >
      <Router>
        <NavigationBar />
        <main>
          <Switch>
            <Route path="/resume" exact component={Resume} />
            <Route path="/enroll" exact component={Enroll} />
            <Route path="/" exact component={Landing} />
            <Route path="/next-steps" exact component={NextSteps} />
            <Route path="/content" exact component={Content} />
            <Route
              path="/content/get-experience"
              exact
              component={GetTheExperience}
            />
            <Route
              path="/content/get-interview"
              exact
              component={GetTheInterview}
            />
            <Route
              path="/content/get-offer"
              exact
              component={GetTheOffer}
            />
            <Route
              path="/content/get-experience/fullstack-app1"
              exact
              component={FullStackApp1}
            />
            <Route
              path="/content/get-experience/fullstack-app2"
              exact
              component={FullStackApp2}
            />
            <Route
              path="/content/get-experience/algo-app1"
              exact
              component={AlgoApp1}
            />
            <Route
              path="/content/get-experience/algo-app2"
              exact
              component={AlgoApp2}
            />
            <Route
              path="/content/get-experience/combo-app"
              exact
              component={ComboApp}
            />
            <Route
              path="/content/get-experience/lowlevel-app"
              exact
              component={LowLevelApp}
            />
            <Route
              path="/content/get-interview/cracking-oa"
              exact
              component={CrackingOA}
            />
            <Route
              path="/content/get-interview/build-best-resume"
              exact
              component={BuildBestResume}
            />
            <Route
              path="/content/get-offer/fundamental-dsa"
              exact
              component={FundamentalDSA}
            />
            <Route
              path="/content/get-offer/coding-patterns"
              exact
              component={CodingPatterns}
            />
            <Route
              path="/content/get-offer/system-design"
              exact
              component={SystemDesign}
            />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/profile" exact component={Profile} />
            <Redirect to="/" />
          </Switch>
        </main>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
